import { useRecoilValue } from 'recoil';
import { authState } from '../states/app.state';

export function useHeaders() {
	const auth = useRecoilValue(authState);

	return {
		headers: {
			Authorization: `Bearer ${auth.token}`,
		},
	};
}
