import { GroupWithCategory, Location } from './page.interface';
import { ProductWithReviewsAndWebsite, Review } from './product.interface';

export type Post = {
	id: number;
	title: string;
	alias: string;
	image: string;
	content: string;
	contentPreview: string;
	createdAt: Date;
	metaTitle: string;
	metaDescription: string;
	websiteId: number;
	singleWebsiteId: number;
	views: number;
	groupId: number | null;
	price?: number;
	oldPrice?: number;
	link?: string;
};

export type Company = {
	id: number;
	name: string;
	alias: string;
	description: string;
	image: string;
	link: string;
	metaTitle: string;
	metaDescription: string;
	advantages: string[];
	disAdvantages: string[];
	rating: number;
	orgType: OrgType;
	createdAt: Date;
	locations: Location[];
	locationIds?: number[];
	isCPApartner: boolean;
	balance?: number;
	companyAnalytics: CompanyAnalytics[];
	admitadId?: number;
	advcakeId?: string;
	alternateName?: string;
	cpaCompanyId?: number;
	subcategory: CompanySubCategory[];
	subcategoryIds?: number[];
};

export type CompanyCategory = {
	id: number;
	name: string;
	alias: string;
	description: string;
	image: string;
};

export type CompanySubCategory = {
	id: number;
	name: string;
	alias: string;
	description: string;
	companyCategoryId: number;
};

export type CompanyAnalytics = {
	id: number;
	month: number;
	year: number;
	views: number;
	clicks: number;
	websiteId: number;
	companyId: number;
};

export type User = {
	id: number;
	email: string;
	password: string;
	name: string;
	role: UserRole;
};

export enum UserRole {
	ADMIN = 'ADMIN',
	COMPANY = 'COMPANY',
}

export type UserWithCompany = User & {
	Company: Company[];
};

export type CompanyWithReviews = Company & {
	_count: {
		codes: number;
	};
	review: Review[];
};

export type Author = {
	id: number;
	name: string;
	alias: string;
	description: string;
	image: string;
	link: string | null;
	rating: number;
	createdAt: Date;
	isCPApartner: boolean;
	AuthorAnalytics: AuthorAnalytics[];
};

export type AuthorAnalytics = {
	id: number;
	month: number;
	year: number;
	authorId: number;
	views: number;
	clicks: number;
	websiteId: number;
};

export type AuthorWithProducts = Author & {
	product: ProductWithReviewsAndWebsite[];
};

export enum OrgType {
	SCHOOL = 'SCHOOL',
	SERVICES = 'SERVICES',
	DIGITAL = 'DIGITAL',
	PROMOCODES = 'PROMOCODES',
	UNIVERSITY = 'UNIVERSITY',
	COLLEGE = 'COLLEGE',
}

export const orgTypes = [
	{ name: 'Школы', value: OrgType.SCHOOL },
	{ name: 'Сервисы', value: OrgType.SERVICES },
	{ name: 'Ratingly', value: OrgType.DIGITAL },
	{ name: 'Промокоды', value: OrgType.PROMOCODES },
	{ name: 'ВУЗы', value: OrgType.UNIVERSITY },
	{ name: 'Колледжи', value: OrgType.COLLEGE },
];

export type PostWithProducts = Post & {
	group: GroupWithCategory & {
		products: ProductWithReviewsAndWebsite[];
	};
};

export type PostCategory = {
	id: number;
	name: string;
	alias: string;
	parentId: number | null;
	metaTitle: string | null;
	metaDescription: string | null;
	websiteId: number | null;
	isDeleted: boolean;
};
