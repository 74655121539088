import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	ApartmentOutlined,
	ShoppingCartOutlined,
	HomeOutlined,
	LogoutOutlined,
	MessageOutlined,
	UnorderedListOutlined,
	UserOutlined,
	QrcodeOutlined,
	GlobalOutlined,
	FileTextOutlined,
	FolderOutlined,
	FolderOpenOutlined,
	RocketOutlined,
	YoutubeOutlined,
	CommentOutlined,
	BarsOutlined,
} from '@ant-design/icons';
import './layout.css';
import { ReactComponent as Logo } from './logo.svg';

const { Header, Sider, Content } = Layout;

export function LayoutComponent({ children }: any) {
	const navigate = useNavigate();
	const location = useLocation();
	const [isOpened, setIsOpened] = useState<boolean>(true);

	const goTo = (path: string) => {
		navigate(path);
	};

	const logout = () => {
		localStorage.removeItem('token');
		navigate('/login');
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider trigger={null} collapsible collapsed={!isOpened}>
				<div className="logo-wrapper">
					<Logo className="logo" />
				</div>
				<Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
					<Menu.Item onClick={() => goTo('/')} key="/" icon={<ApartmentOutlined />}>
						Категории
					</Menu.Item>
					<Menu.Item
						onClick={() => goTo('/groups')}
						key="/groups"
						icon={<UnorderedListOutlined />}
					>
						Подборки
					</Menu.Item>
					<Menu.Item
						onClick={() => goTo('/products')}
						key="/products"
						icon={<ShoppingCartOutlined />}
					>
						Продукты
					</Menu.Item>
					<Menu.SubMenu title="Компании" icon={<HomeOutlined />}>
						<Menu.Item
							onClick={() => goTo('/companies')}
							key="/companies"
							icon={<HomeOutlined />}
						>
							Список
						</Menu.Item>
						<Menu.Item
							onClick={() => goTo('/company-categories')}
							key="/company-categories"
							icon={<FolderOutlined />}
						>
							Категории
						</Menu.Item>
						<Menu.Item
							onClick={() => goTo('/company-subcategories')}
							key="/company-subcategories"
							icon={<FolderOpenOutlined />}
						>
							Подкатегории
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.Item
						onClick={() => goTo('/authors')}
						key="/authors"
						icon={<UserOutlined />}
					>
						Авторы
					</Menu.Item>
					<Menu.Item
						onClick={() => goTo('/codes')}
						key="/codes"
						icon={<QrcodeOutlined />}
					>
						Промокоды
					</Menu.Item>
					<Menu.Item
						onClick={() => goTo('/events')}
						key="/events"
						icon={<YoutubeOutlined />}
					>
						События
					</Menu.Item>
					<Menu.Item
						onClick={() => goTo('/mono')}
						key="/mono"
						icon={<FileTextOutlined />}
					>
						Моно сайты
					</Menu.Item>
					<Menu.Item
						onClick={() => goTo('/addresses')}
						key="/addresses"
						icon={<GlobalOutlined />}
					>
						Адреса
					</Menu.Item>
					<Menu.SubMenu title="Блог" icon={<CommentOutlined />}>
						<Menu.Item
							onClick={() => goTo('/blog')}
							key="/blog"
							icon={<MessageOutlined />}
						>
							Записи
						</Menu.Item>
						<Menu.Item
							onClick={() => goTo('/blog-categories')}
							key="/blog-categories"
							icon={<BarsOutlined />}
						>
							Категории
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.Item
						onClick={() => goTo('/partners')}
						key="/partners"
						icon={<RocketOutlined />}
					>
						Партнёры
					</Menu.Item>
					<Menu.Item key="4" icon={<LogoutOutlined />} onClick={logout}>
						Выход
					</Menu.Item>
				</Menu>
			</Sider>
			<Layout className="site-layout">
				<Header className="site-layout-background" style={{ padding: 0 }}>
					{React.createElement(isOpened ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: () => setIsOpened(!isOpened),
					})}
				</Header>
				<Content
					className="site-layout-background"
					style={{
						margin: '24px 16px',
						padding: 24,
						minHeight: 280,
					}}
				>
					{children}
				</Content>
			</Layout>
		</Layout>
	);
}
