import { Button } from 'antd';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import Uploader from '../../components/uploader/uploader';
import { useHeaders } from '../../helpers/useHeaders';

export function ImportCompany({ save }: ImportCompanyProps) {
	const [file, setFile] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [panel, setPanel] = useState<{
		messages: string[];
		appearence: 'success' | 'error';
		success?: number;
		errors?: number;
		exists?: number;
	}>();
	const header = useHeaders();

	const importFile = async () => {
		try {
			setIsLoading(true);
			if (!file) {
				return;
			}
			const { data } = await axios.post<{
				success: number;
				errors: number;
				exists: number;
				messages: string[];
			}>('/api-v2/company/import', { url: file }, header);
			setPanel({
				appearence: 'success',
				messages: data.messages,
				success: data.success,
				errors: data.errors,
				exists: data.exists,
			});
		} catch (e) {
			if (e instanceof AxiosError) {
				setPanel({ appearence: 'error', messages: [e.response?.data.message] });
			}
		}
		setIsLoading(false);
	};

	return (
		<>
			<a
				href="https://cdn-bucket.hb.bizmrg.com/topinc-images/1/template.xlsx"
				target="_blank"
			>
				Скачать шаблон
			</a>
			{panel && (
				<>
					<div>
						Успешно: {panel.success}
						<br /> Не удалось: {panel.errors}
						<br /> Существуют: {panel.exists}
					</div>
					{panel.messages.map((m) => (
						<div key="m">{m}</div>
					))}
				</>
			)}
			<Uploader text="Файл xlsx" onChange={setFile} value={file} entity={'import'} />
			{isLoading ? (
				'Загружаю'
			) : (
				<Button className="form-submit" onClick={importFile}>
					Импортировать
				</Button>
			)}
		</>
	);
}

export interface ImportCompanyProps {
	save(): void;
}
