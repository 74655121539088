import { Button, Card, DatePicker, Form, Input, InputNumber, Rate } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { format } from 'date-fns';
import { useHeaders } from '../../helpers/useHeaders';
import { Website } from '../../interfaces/page.interface';
import { Author, Company } from '../../interfaces/post.interface';
import { Product, Review } from '../../interfaces/product.interface';

export function EditReview({
	reviews,
	company,
	author,
	product,
	website,
	save,
	close,
}: EditReviewProps) {
	const [form] = Form.useForm();
	const headers = useHeaders();
	const addReview = async (res: Review) => {
		if (company) {
			res.companyId = company.id;
		}
		if (author) {
			res.authorId = author.id;
		}
		if (product) {
			res.productId = product.id;
		}
		res.websiteId = website.id;
		await axios.post<Review>(
			'/api-v2/review',
			{
				...res,
			},
			headers
		);
		form.resetFields();
		save();
	};

	const deleteReview = async (review: Review) => {
		await axios.delete<Review>('/api-v2/review/' + review.id, headers);
		save();
	};

	return (
		<>
			{reviews?.map((r) => (
				<Card key={r.id} title={r.title}>
					<div>
						<Rate value={r.rating} allowHalf disabled />
					</div>
					<div>
						<b>Имя: </b>
						{r.name}
					</div>
					<div>{r.description}</div>
					<div>
						<b>Дата: </b>
						{format(new Date(r.createdAt), 'yyyy-MM-dd')}
					</div>
					<Button type="ghost" onClick={() => deleteReview(r)}>
						Удалить
					</Button>
				</Card>
			))}
			<Form form={form} onFinish={addReview} layout="vertical">
				<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item name="title" label="Заголовок" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item name="description" label="Описание" rules={[{ required: true }]}>
					<TextArea rows={4} />
				</Form.Item>
				<Form.Item name="rating" label="Рейтинг" rules={[{ required: true }]}>
					<InputNumber min={1} max={5} step={0.1} />
				</Form.Item>
				<Form.Item name="createdAt" label="Дата создания" rules={[{ required: true }]}>
					<DatePicker />
				</Form.Item>
				<Form.Item name="source" label="Источник отзыва">
					<Input />
				</Form.Item>
				<Form.Item name="sourceUrl" label="URL источника отзыва">
					<Input />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Сохранить
					</Button>
				</Form.Item>
			</Form>
			<Button type="ghost" onClick={close}>
				Закрыть
			</Button>
		</>
	);
}

export interface EditReviewProps {
	reviews?: Review[];
	company?: Company;
	author?: Author;
	website: Website;
	product?: Product;
	save(): void;
	close(): void;
}
