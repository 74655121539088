import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHeaders } from '../../helpers/useHeaders';
import { CompanyCategory, CompanySubCategory } from '../../interfaces/post.interface';
import { EditCompanySubCategory } from './edit-company-subcategory';
const { Option } = Select;

export function CompanySubCategories() {
	const [categories, setCategories] = useState<CompanyCategory[]>([]);
	const [category, setCategory] = useState<CompanyCategory>();
	const [subCategories, setSubCategories] = useState<CompanySubCategory[]>([]);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [editedSubCategory, setEditedSubCategory] = useState<CompanySubCategory | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		const { data } = await axios.get<CompanyCategory[]>('/api-v2/company-category');
		setCategories(data);
		setCategory(data[0]);
		const { data: sub } = await axios.get<CompanySubCategory[]>('/api-v2/company-subcategory');
		setSubCategories(sub);
	};

	const deleteSubCategory = async (category: CompanySubCategory) => {
		await axios.delete<CompanyCategory[]>(
			'/api-v2/company-subcategory/' + category.id,
			headers
		);
		loadData();
	};

	const startEdit = (category: CompanySubCategory) => {
		setEditedSubCategory(category);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedSubCategory(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedSubCategory(undefined);
		setIsEdited(false);
		loadData();
	};

	const selectCategory = async (category: CompanyCategory) => {
		const { data: sub } = await axios.get<CompanySubCategory[]>(
			'/api-v2/company-subcategory/category/' + category.alias
		);
		setSubCategories(sub);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteSubCategory(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						{categories && category && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectCategory(
										categories.find((w) => w.id === value) ?? category
									)
								}
								placeholder="Категория"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{categories.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>

				<Table columns={columns} dataSource={subCategories} />
			</Space>
			<Drawer
				title="Редактирование категории"
				placement="right"
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditCompanySubCategory
						categories={categories}
						editedCategory={editedSubCategory}
						save={saveEdit}
					/>
				)}
			</Drawer>
		</>
	);
}
