import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditAddress } from './edit-address';
import { useHeaders } from '../../helpers/useHeaders';
import { Address } from '../../interfaces/address.interface';
import { countries, Country, Location } from '../../interfaces/page.interface';

const { Option } = Select;

export function AddressPage() {
	const [data, setData] = useState<Address[]>([]);
	const [locations, setLocations] = useState<Location[]>([]);
	const [selectedCountry, setSelectedCountry] = useState<Country>(Country.RU);
	const [selectedLocation, setSelectedLocation] = useState<Location>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [editedAddress, setEditedAddress] = useState<Address | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadLocations(selectedCountry);
	}, []);

	useEffect(() => {
		if (selectedLocation) {
			loadData(selectedLocation);
		}
	}, [selectedLocation]);

	const loadLocations = async (country: Country) => {
		const { data } = await axios.get<Location[]>(
			'/api-v2/location/country/' + country,
			headers,
		);
		setLocations(data);
		setSelectedLocation(data[0]);
	};

	const loadData = async (location?: Location) => {
		if (!location) {
			return;
		}
		const { data } = await axios.get<Address[]>('/api-v2/address/byLocation', {
			...headers,
			params: {
				locationId: location.id,
			},
		});
		setData(data);
	};

	const deleteAddress = async (address: Address) => {
		await axios.delete<any[]>('/api-v2/address/' + address.id, headers);
		loadData(selectedLocation);
	};

	const startEdit = (address: Address) => {
		setEditedAddress(address);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedAddress(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedAddress(undefined);
		setIsEdited(false);
		loadData(selectedLocation);
	};

	const selectCountry = (country: Country) => {
		setSelectedCountry(country);
		loadLocations(country);
	};

	const selectLocation = (location: Location) => {
		setSelectedLocation(location);
		loadData(location);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Адрес',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Телефон',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteAddress(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) =>
								selectCountry(
									countries.find((w) => w.value === value)?.value ?? Country.RU,
								)
							}
							value={selectedCountry}
							placeholder="Страна"
							optionFilterProp="children"
							filterOption={(inputValue: string, option?: any) =>
								option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
								0
							}
						>
							{countries.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) =>
								selectLocation(
									locations.find((w) => w.id === value) ?? locations[0],
								)
							}
							value={selectedLocation?.id}
							placeholder="Город"
							optionFilterProp="children"
							filterOption={(inputValue: string, option?: any) =>
								option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
								0
							}
						>
							{locations.map((w) => (
								<Option key={w.id} value={w.id}>
									{w.ruName}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование адреса"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && <EditAddress editedAddess={editedAddress} save={saveEdit} />}
			</Drawer>
		</>
	);
}
