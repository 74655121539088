import { Button, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Quill from '../../components/quill/quill';
import Uploader from '../../components/uploader/uploader';
import { useHeaders } from '../../helpers/useHeaders';
import { Category, Group, sections } from '../../interfaces/page.interface';
import { Post } from '../../interfaces/post.interface';
import { SingleWebsite, singleWebsiteTypes } from '../../interfaces/single-website.interface';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Tags } from '../../components';

const { Option } = Select;

export function EditMono({ editedWebsite, save }: EditCodeProps) {
	const headers = useHeaders();
	const [groups, setGroups] = useState<Group[]>();

	useEffect(() => {
		loadGroups();
	}, []);

	const loadGroups = async () => {
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		setGroups(data);
	};
	const saveData = async (res: Post) => {
		if (!editedWebsite?.id) {
			await axios.post<Post>(
				'/api-v2/website/single',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/website/single/' + editedWebsite.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={editedWebsite} layout="vertical">
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="name" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="h1" label="H1">
						<Input />
					</Form.Item>
					<Form.Item name="logo" noStyle getValueFromEvent={(e) => e}>
						<Uploader entity="single-website" text="Логотип" />
					</Form.Item>
					<Form.Item name="faviconIco" noStyle getValueFromEvent={(e) => e}>
						<Uploader entity="single-website" text="favicon ico" />
					</Form.Item>
					<Form.Item name="faviconPng" noStyle getValueFromEvent={(e) => e}>
						<Uploader entity="single-website" text="favicon png" />
					</Form.Item>
					{groups && (
						<Form.Item name="groupId" label="Группа" rules={[{ required: true }]}>
							<Select
								showSearch
								placeholder="Категория"
								optionFilterProp="name"
								filterOption={(inputValue: string, option?: any) =>
									option.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{groups.map((g) => (
									<Option key={g.id} value={g.id}>
										{g.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					<Form.Item name="type" label="Тип" rules={[{ required: true }]}>
						<Select placeholder="Тип">
							{singleWebsiteTypes.map((g) => (
								<Option key={g.value} value={g.value}>
									{g.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="authorName" label="Имя автора" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="authorSurname"
						label="Фамилия автора"
						rules={[{ required: true }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="authorDescription"
						label="Описание автора"
						rules={[{ required: true }]}
					>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="authorPhoto" noStyle getValueFromEvent={(e) => e}>
						<Uploader entity="single-website" text="Изображение автора" />
					</Form.Item>
					<Form.Item name="metaTitle" label="MetaTitle" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="metaDescription"
						label="MetaDescription"
						rules={[{ required: true }]}
					>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="tags" label="Теги">
						<Tags />
					</Form.Item>
					<Form.Item name="metrikaId" label="metrikaId">
						<Input />
					</Form.Item>
					<Form.Item name="yandexVerification" label="yandexVerification">
						<Input />
					</Form.Item>
					<Form.Item name="googleVerification" label="googleVerification">
						<Input />
					</Form.Item>
					<Form.Item name="currency" label="Валюта">
						<Input />
					</Form.Item>
					<Form.Item name="actionText" label="Текст кнопки">
						<Input />
					</Form.Item>
				</Col>
				<Col span={14}>
					<Form.Item name="html" label="HTML текст">
						<Quill />
					</Form.Item>
					<Form.List name="questions">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => (
									<div
										key={key}
										style={{
											display: 'flex',
											gap: 8,
											flexDirection: 'column',
										}}
									>
										<div
											key={key}
											style={{
												display: 'flex',
												gap: 8,
												alignItems: 'center',
											}}
										>
											<Form.Item
												{...restField}
												name={[name, 'question']}
												style={{ marginBottom: 0, width: '100%' }}
												rules={[{ required: true }]}
											>
												<Input
													placeholder="Вопрос"
													style={{ width: '100%' }}
												/>
											</Form.Item>
											<MinusCircleOutlined onClick={() => remove(name)} />
										</div>
										<Form.Item
											{...restField}
											style={{ width: '100%' }}
											name={[name, 'answer']}
											rules={[{ required: true }]}
										>
											<TextArea rows={4} style={{ width: '100%' }} />
										</Form.Item>
									</div>
								))}
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => add()}
										block
										icon={<PlusOutlined />}
									>
										Добавить вопрос
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCodeProps {
	editedWebsite?: SingleWebsite;
	save(): void;
}
