import { Country } from './page.interface';
import { Company } from './post.interface';

export type ProductWithReviewsAndWebsite = Product & {
	review: Review[];
	websiteProduct: WebsiteProduct[];
	productAnalytics: ProductAnalytics[];
	productCountry: ProductCountry[];
	characteristics: Characteristic[];
	company: Company;
	groupIds: number[];
	groups: GroupOnProduct[];
};

export type GroupOnProduct = {
	productId: number;
	groupId: number;
	partnerEndDate: Date | null;
	placementEndDate: Date | null;
};

export type ProductAnalytics = {
	id: number;
	date: Date;
	productId: number;
	clicks: number;
	websiteId: number;
};

export type Product = {
	id: number;
	alias: string | null;
	image: string;
	bigImage: string | null;
	title: string;
	description: string | null;
	advantages: string | null;
	disAdvantages: string | null;
	tags: string[];
	createdAt: Date;
	updatedAt: Date;
	companyId: number | null;
	authorId: number | null;
	isHidden: boolean;
};

export type ProductCountry = {
	id: number;
	productId: number;
	link: string;
	price: number;
	oldPrice: number | null;
	credit: number | null;
	country: Country;
	currency: Currency;
};

export enum Currency {
	RUB = 'RUB',
	KZT = 'KZT',
	BYN = 'BYN',
}

export const countryToCurrency = [
	{ country: Country.RU, currency: Currency.RUB },
	{ country: Country.BY, currency: Currency.BYN },
	{ country: Country.KZ, currency: Currency.KZT },
];

export const currencies = [
	{ name: '₽', value: Currency.RUB },
	{ name: '₸', value: Currency.KZT },
	{ name: 'BYN', value: Currency.BYN },
];

export type WebsiteProduct = {
	id: number;
	websiteId: number;
	html: string;
	initialRating: number;
	productId: number;
};

export type Review = {
	id: number;
	name: string;
	title: string;
	description: string;
	createdAt: Date;
	rating: number;
	companyId: number;
	authorId: number;
	productId: number | null;
	websiteId: number;
};

export type Characteristic = {
	id: number;
	name: string;
	value: string;
	productId: number;
};
