import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHeaders } from '../../helpers/useHeaders';
import { Address } from '../../interfaces/address.interface';
import { Code, codeTypes } from '../../interfaces/codes.interface';
import {
	Category,
	countries,
	Country,
	Group,
	Location,
	sections,
	Website,
} from '../../interfaces/page.interface';
import { Company, OrgType, orgTypes, Post } from '../../interfaces/post.interface';

const { Option } = Select;

export function EditAddress({ editedAddess, save }: EditAddressProps) {
	const headers = useHeaders();
	const [groups, setGroups] = useState<Group[]>();
	const [country, setCountry] = useState<Country>(Country.RU);
	const [locations, setLocations] = useState<Location[]>();

	useEffect(() => {
		loadGroups();
		loadLocations(country);
	}, []);

	const loadLocations = async (selectedCountry: Country) => {
		const { data } = await axios.get<Location[]>(
			'/api-v2/location/country/' + selectedCountry,
			headers,
		);
		setLocations(data);
	};

	const loadGroups = async () => {
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		setGroups(data);
	};

	const selectCountry = (selectedCountry: Country) => {
		setCountry(selectedCountry);
		loadLocations(selectedCountry);
	};

	const saveData = async (res: Post) => {
		if (!editedAddess?.id) {
			await axios.post<Post>(
				'/api-v2/address',
				{
					...res,
				},
				headers,
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/address/' + editedAddess.id,
				{
					...res,
				},
				headers,
			);
		}
		save();
	};

	return (
		<Form
			onFinish={saveData}
			initialValues={{
				...editedAddess,
				groupIds: editedAddess?.addressOnGroup.map((w) => w.groupId),
			}}
			layout="vertical"
		>
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="name" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="address" label="Адрес" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="phone" label="Телефон">
						<Input />
					</Form.Item>
				</Col>
				<Col span={14}>
					{groups && (
						<Form.Item name="groupIds" label="Группы" rules={[{ required: true }]}>
							<Select
								placeholder="Группы"
								mode="multiple"
								optionFilterProp="children"
							>
								{groups.map((group) => (
									<Option key={group.id} value={group.id}>
										{group.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					{locations && (
						<Space direction="vertical">
							<Select
								placeholder="Страна"
								onChange={(value: Country) => selectCountry(value)}
								value={country}
							>
								{countries.map((c) => (
									<Option key={c.value} value={c.value}>
										{c.name}
									</Option>
								))}
							</Select>
							<Form.Item
								name="locationId"
								label="Расположение"
								rules={[{ required: true }]}
							>
								<Select placeholder="Расположение" optionFilterProp="children">
									{locations.map((location) => (
										<Option key={location.id} value={location.id}>
											{location.ruName ??
												countries.find((c) => c.value === location.country)
													?.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Space>
					)}
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditAddressProps {
	editedAddess?: Address;
	save(): void;
}
