import { atom, DefaultValue } from 'recoil';

function localStorageEffect(key: string) {
	return ({ setSelf, onSet }: any) => {
		if (typeof window === 'undefined') {
			return;
		}
		const savedValue = localStorage.getItem(key);
		if (savedValue != null) {
			setSelf(JSON.parse(savedValue));
		}
		onSet((newValue: any) => {
			if (newValue instanceof DefaultValue) {
				localStorage.removeItem(key);
			} else {
				localStorage.setItem(key, JSON.stringify(newValue));
			}
		});
	};
}

const getInitialAuthState = () => {
	const savedValue = localStorage.getItem('state');
	if (!savedValue) {
		return INITIAL_AUTH_STATE;
	}
	return JSON.parse(savedValue);
};

export const INITIAL_AUTH_STATE = {
	isAuthed: false,
	token: null,
};

export const authState = atom<IAuthState>({
	key: 'authState',
	default: getInitialAuthState(),
	effects_UNSTABLE: [localStorageEffect('state')],
});

export interface IAuthState {
	isAuthed: boolean;
	token: string | null;
}
