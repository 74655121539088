import { Author } from './post.interface';
import { ProductWithReviewsAndWebsite, Review } from './product.interface';

export type Location = {
	id: number;
	country: Country;
	city: string | null;
	ruName: string | null;
	ruNameIn: string | null;
};

export enum Country {
	RU = 'RU',
	KZ = 'KZ',
	BY = 'BY',
}

export const countries = [
	{ name: 'Россия', value: Country.RU },
	{ name: 'Казахстан', value: Country.KZ },
	{ name: 'Белорусия', value: Country.BY },
];

export type Group = {
	id: number;
	name: string;
	title: string;
	order: number | null;
	alias: string;
	categoryId: number;
	cpcCost?: number;
	tags: string[];
};

export type GroupForPage = Group & {
	groupAnalytics: GroupAnalytics[];
	products: ProductGroupInfo[];
	category: Category;
	websiteGroup: WebsiteGroup[];
};

export type GroupAnalytics = {
	id: number;
	month: number;
	year: number;
	views: number;
	clicks: number;
	groupId: number;
	websiteId: number;
};

export type ProductGroupInfo = {
	GroupOnProductAnalytics: GroupOnProductAnalytics[];
	partnerEndDate: Date;
	placementEndDate: Date;
	isCpc?: boolean;
	product: ProductWithReviewsAndWebsite;
};

export type GroupOnProductAnalytics = {
	id: number;
	groupOnProductProductId: number;
	groupOnProductGroupId: number;
	month: number;
	year: number;
	clicks: number;
	websiteId: number;
};

export type WebsiteGroup = {
	id?: number;
	websiteId: number;
	metaTitle?: string;
	metaDescription?: string;
	html?: string;
	questions?: {
		question: string;
		answer: string;
	}[];
	groupId: number | null;
};

export type AuthorsPage = {
	authors: AuthorWithProducts[];
	totalPages: number;
	page: number;
};

export type AuthorWithProducts = Author & {
	product: ProductWithReviewsAndWebsite[];
	review: Review[];
};

export type GroupWithCategory = Group & {
	category: Category;
};

export type Category = {
	id: number;
	section: Section;
	name: string;
	order: number | null;
	alias: string;
};

export type CategoryWithGroups = Category & {
	group: Group[];
	isOpened: boolean;
};

export enum Section {
	COURSES = 'COURSES',
	SERVICES = 'SERVICES',
	SCHOOL = 'SCHOOL',
	STUDENT = 'STUDENT',
	DIGITAL = 'DIGITAL',
	PROMOCODES = 'PROMOCODES',
	UNIVERSITY = 'UNIVERSITY',
	COLLEGE = 'COLLEGE',
}

export type Website = {
	id: number;
	name: string;
	country: Country;
};

export const sections = [
	{ name: 'Курсы', value: Section.COURSES },
	{ name: 'Студентам', value: Section.STUDENT },
	{ name: 'Школьника', value: Section.SCHOOL },
	{ name: 'Сервисы', value: Section.SERVICES },
	{ name: 'Ratingly', value: Section.DIGITAL },
	{ name: 'Промокоды', value: Section.PROMOCODES },
	{ name: 'ВУЗы', value: Section.UNIVERSITY },
	{ name: 'Колледжи', value: Section.COLLEGE },
];
