import { Button, Col, DatePicker, Drawer, Input, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined, MessageOutlined } from '@ant-design/icons';
import { EditAuthor } from './edit-author';
import { useHeaders } from '../../helpers/useHeaders';
import { Author } from '../../interfaces/post.interface';
import { AuthorsPage, AuthorWithProducts, Website } from '../../interfaces/page.interface';
import { EditReview } from '../products/edit-review';
import { addDays, startOfMonth } from 'date-fns';
import moment from 'moment';
const { Option } = Select;

export function Authors() {
	const [data, setData] = useState<Author[]>([]);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [editedAuthor, setEditedAuthor] = useState<Author | undefined>();
	const [isEditedReviews, setIsEditedReviews] = useState<boolean>(false);
	const [editedReviews, setEditedReviews] = useState<AuthorWithProducts>();
	const [websites, setWebsites] = useState<Website[]>();
	const [website, setWebsite] = useState<Website>();
	const [date, setDate] = useState<Date>(new Date());
	const headers = useHeaders();
	const [search, setSearch] = useState<string>('');
	const [filteredData, setFilteredData] = useState<Author[]>([]);

	useEffect(() => {
		setFilteredData(data.filter((x) => x.name.toLowerCase().includes(search.toLowerCase())));
	}, [search]);

	useEffect(() => {
		loadWebsites();
	}, []);

	useEffect(() => {
		if (date && website) {
			loadData(date, website);
		}
	}, [date, website]);

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
		setWebsite(websitesData[0]);
	};

	const loadData = async (date: Date, website?: Website) => {
		const { data } = await axios.get<AuthorsPage>('/api-v2/author/all', {
			...headers,
			params: {
				skip: 0,
				take: 1000,
				website: website?.name,
				date,
			},
		});
		setData(data.authors);
		setFilteredData(data.authors);
		setEditedReviews(data.authors.find((p) => p.id === editedReviews?.id));
	};

	const deleteAuthor = async (author: Author) => {
		await axios.delete<any[]>('/api-v2/author/' + author.id, headers);
		loadData(date, website);
	};

	const startEdit = (author: Author) => {
		setEditedAuthor(author);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedAuthor(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedAuthor(undefined);
		setIsEdited(false);
		loadData(date);
	};

	const startEditReviews = (product: AuthorWithProducts) => {
		setEditedReviews(product);
		setIsEditedReviews(true);
	};

	const closeEditReviews = () => {
		setEditedReviews(undefined);
		setIsEditedReviews(false);
		loadData(date, website);
	};

	const saveEditReviews = async () => {
		loadData(date, website);
	};

	const selectWebsite = (website: Website) => {
		setWebsite(website);
		loadData(date, website);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Рейтинг',
			dataIndex: 'rating',
			key: 'rating',
			render: (text: number) => <>{text.toFixed(2)}</>,
		},
		{
			title: 'Просмотры',
			dataIndex: 'views',
			key: 'views',
			render: (text: number, record: Author) => <>{record.AuthorAnalytics[0]?.views ?? 0}</>,
		},
		{
			title: 'Переходы',
			dataIndex: 'clicks',
			key: 'clicks',
			render: (text: number, record: Author) => <>{record.AuthorAnalytics[0]?.clicks ?? 0}</>,
		},
		{
			title: 'CTR',
			dataIndex: 'ctr',
			key: 'ctr',
			render: (text: number, record: Author) => {
				if (!record.AuthorAnalytics[0]?.clicks) {
					return <>0%</>;
				} else {
					const conv =
						(record.AuthorAnalytics[0]?.clicks /
							(record.AuthorAnalytics[0]?.views ?? 0)) *
						100;
					return <>{conv.toFixed(0)}%</>;
				}
			},
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => startEditReviews(record)}>
						<MessageOutlined /> {record.review?.length ?? 0}
					</Button>
					<Button onClick={() => deleteAuthor(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						{websites && website && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectWebsite(websites.find((w) => w.id === value) ?? website)
								}
								value={website.name}
								placeholder="Сайт"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{websites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						<DatePicker
							onChange={(value) =>
								value && setDate(addDays(startOfMonth(value.toDate()), 1))
							}
							value={moment(date)}
							picker="month"
						/>
					</Col>
					<Col>
						<Input onChange={(e) => setSearch(e.target.value)} />
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={filteredData} />
			</Space>
			<Drawer
				title="Редактирование автора"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && <EditAuthor editedAuthor={editedAuthor} save={saveEdit} />}
			</Drawer>
			<Drawer
				title="Редактирование Отзывов"
				placement="right"
				width={'90%'}
				onClose={closeEditReviews}
				visible={isEditedReviews}
			>
				{editedReviews && website && (
					<EditReview
						save={saveEditReviews}
						reviews={editedReviews.review}
						author={editedReviews}
						website={website}
						close={closeEditReviews}
					/>
				)}
			</Drawer>
		</>
	);
}
