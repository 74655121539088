import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IUploaderProps } from './uploader.props';
import './uploader.css';
import { useHeaders } from '../../helpers/useHeaders';

const Uploader = ({ value, onChange, text, entity }: IUploaderProps) => {
	const [loading, SetIsLoading] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<string | undefined>(value);
	const [fileName, setFileName] = useState<string | undefined>(value);
	const headers = useHeaders();

	useEffect(() => {
		setImageUrl(value);
	}, [value]);

	useEffect(() => {
		if (imageUrl && onChange) {
			onChange(imageUrl);
		}
	}, [imageUrl]);

	const beforeUpload = (file: any) => {
		const isLt20M = file.size / 1024 / 1024 < 20;
		if (!isLt20M) {
			message.error('Изображение должно быть меньше 20MB!');
		}
		return isLt20M;
	};

	const handleChange = (info: any) => {
		if (info.file.status === 'uploading') {
			SetIsLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			SetIsLoading(false);
			setFileName(info.file.name);
			setImageUrl(info.file.response.url);
		}
	};

	return (
		<Upload
			name="files"
			listType="picture-card"
			className="uploader"
			showUploadList={false}
			action={`/api-v2/files/upload?folder=${entity}`}
			headers={headers.headers}
			beforeUpload={beforeUpload}
			onChange={handleChange}
		>
			{imageUrl ? (
				<img src={imageUrl} alt="" style={{ width: '19%', marginRight: 40 }} />
			) : (
				<div className="uploader">
					{loading ? <LoadingOutlined /> : <PlusOutlined />}
					<div className="ant-upload-text">Загрузить</div>
				</div>
			)}
			{text}
		</Upload>
	);
};

export default Uploader as React.FC<IUploaderProps>;
