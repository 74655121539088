import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Tags } from '../../components';
import Quill from '../../components/quill/quill';
import Uploader from '../../components/uploader/uploader';
import { useHeaders } from '../../helpers/useHeaders';
import { countries, Country, Location } from '../../interfaces/page.interface';
import {
	Company,
	CompanyCategory,
	CompanySubCategory,
	CompanyWithReviews,
	OrgType,
	orgTypes,
} from '../../interfaces/post.interface';

const { Option } = Select;

export function EditCompany({ editedCompany, save }: EditPostProps) {
	const modifiedCompany = {
		...editedCompany,
		locationIds: editedCompany?.locations.map((l) => l.id),
		subcategoryIds: editedCompany?.subcategory.map((l) => l.id),
	};
	const headers = useHeaders();
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [country, setCountry] = useState<Country>(Country.RU);
	const [locations, setLocations] = useState<Location[]>();
	const [categories, setCategories] = useState<CompanyCategory[]>();
	const [companies, setCompanies] = useState<Company[]>([]);
	const [subCategories, setSubCategories] = useState<CompanySubCategory[]>([]);

	useEffect(() => {
		loadCategories();
		loadLocations(country);
		loadCompanies(editedCompany?.orgType ?? orgType);
	}, []);

	const loadLocations = async (selectedCountry: Country) => {
		const { data } = await axios.get<Location[]>(
			'/api-v2/location/country/' + selectedCountry,
			headers
		);
		setLocations(data);
	};

	const loadCategories = async () => {
		const { data } = await axios.get<CompanyCategory[]>('/api-v2/company-category');
		setCategories(data);
		const { data: sub } = await axios.get<CompanySubCategory[]>('/api-v2/company-subcategory');
		setSubCategories(sub);
	};

	const loadCompanies = async (type: OrgType) => {
		const { data } = await axios.get<CompanyWithReviews[]>('/api-v2/company/type/' + type, {
			...headers,
		});
		setCompanies(data);
	};

	const selectCountry = (selectedCountry: Country) => {
		setCountry(selectedCountry);
		loadLocations(selectedCountry);
	};

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadCompanies(type);
	};

	const saveData = async (res: Company) => {
		if (!editedCompany?.id) {
			await axios.post<Company>(
				'/api-v2/company',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Company>(
				'/api-v2/company/' + editedCompany.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={modifiedCompany} layout="vertical">
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="name" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="alternateName" label="Другое название">
						<Input />
					</Form.Item>
					<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="link" label="Партнёрская ссылка" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="rating" label="Рейтинг" rules={[{ required: true }]}>
						<InputNumber min={0} max={5} step={0.1} />
					</Form.Item>
					<Form.Item name="metaTitle" label="MetaTitle">
						<Input />
					</Form.Item>
					<Form.Item name="metaDescription" label="MetaDescription">
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="orgType" label="Тип компании" rules={[{ required: true }]}>
						<Select placeholder="Тип компании">
							{orgTypes.map((o) => (
								<Option key={o.value} value={o.value}>
									{o.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					{categories && (
						<Form.Item name="categoryId" label="Категория компании">
							<Select placeholder="Категория компании">
								{categories.map((o) => (
									<Option key={o.id} value={o.id}>
										{o.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					{subCategories && (
						<Form.Item name="subcategoryIds" label="Подкатегории">
							<Select
								placeholder="Подкатегории"
								mode="multiple"
								optionFilterProp="children"
							>
								{subCategories.map((c) => (
									<Option key={c.id} value={c.id}>
										{c.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					<Form.Item name="image" noStyle getValueFromEvent={(e) => e}>
						<Uploader entity="companies" text="Изображение" />
					</Form.Item>
					<Form.Item name="admitadId" label="Id Admitad">
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item name="advcakeId" label="Id Advcake">
						<InputNumber min={0} />
					</Form.Item>
				</Col>
				<Col span={14}>
					<Form.Item name="advantages" label="Преимущества">
						<Tags />
					</Form.Item>
					<Form.Item name="disAdvantages" label="Недостатки">
						<Tags />
					</Form.Item>
					<Form.Item name="description" label="Описание">
						<Quill style={{ height: '40vh' }} />
					</Form.Item>
					<br />
					<br />
					{locations && (
						<Space direction="vertical">
							<Select
								placeholder="Страна"
								onChange={(value: Country) => selectCountry(value)}
								value={country}
							>
								{countries.map((c) => (
									<Option key={c.value} value={c.value}>
										{c.name}
									</Option>
								))}
							</Select>
							<Form.Item
								name="locationIds"
								label="Расположение"
								rules={[{ required: true }]}
							>
								<Select
									placeholder="Расположение"
									mode="multiple"
									optionFilterProp="children"
								>
									{locations.map((location) => (
										<Option key={location.id} value={location.id}>
											{location.ruName ??
												countries.find((c) => c.value === location.country)
													?.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Space>
					)}
					<Form.Item name="isCPApartner" valuePropName="checked">
						<Checkbox>CPA партнёр</Checkbox>
					</Form.Item>
					<Form.Item name="balance" label="Текущий баланс">
						<InputNumber min={0} />
					</Form.Item>
					<Select
						style={{ width: 200 }}
						onSelect={(value: any) => selectType(value)}
						value={orgType}
						placeholder="Тип компании"
					>
						{orgTypes.map((w) => (
							<Option key={w.value} value={w.value}>
								{w.name}
							</Option>
						))}
					</Select>
					{companies && (
						<Form.Item name="cpaCompanyId" label="Партнёрская компания по CPA">
							<Select
								placeholder="Компания"
								showSearch
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{companies.map((company) => (
									<Option key={company.id} value={company.id}>
										{company.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditPostProps {
	editedCompany?: Company;
	save(): void;
}
