import axios from 'axios';
import { useEffect, useState } from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { useHeaders } from '../../helpers/useHeaders';
import { Company } from '../../interfaces/post.interface';

export function CompanyStat({ company }: CompanyStatProps) {
	const headers = useHeaders();
	const [stat, setStat] = useState<CompanyStat[]>();

	useEffect(() => {
		loadStat();
	}, []);

	const loadStat = async () => {
		const { data } = await axios.get<CompanyStat[]>(
			'/api-v2/company/stat/' + company.id,
			headers,
		);
		setStat(data);
	};

	return (
		<>
			<h1>{company.name}</h1>
			{stat && (
				<ResponsiveContainer width="100%" height={400}>
					<BarChart
						width={500}
						height={300}
						data={stat}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar dataKey="clicks" fill="#0018ED" />
					</BarChart>
				</ResponsiveContainer>
			)}
		</>
	);
}

export interface CompanyStatProps {
	company: Company;
}

export interface CompanyStat {
	name: string;
	clicks: number;
}
