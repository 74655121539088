import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useHeaders } from '../../helpers/useHeaders';
import { CompanyCategory, CompanySubCategory } from '../../interfaces/post.interface';
const { Option } = Select;

export function EditCompanySubCategory({ editedCategory, save, categories }: EditCategoryProps) {
	const headers = useHeaders();

	const saveData = async (res: CompanySubCategory) => {
		if (!editedCategory?.id) {
			await axios.post<CompanySubCategory>(
				'/api-v2/company-subcategory',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<CompanySubCategory>(
				'/api-v2/company-subcategory/' + editedCategory.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={editedCategory} layout="vertical">
			<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="description" label="Описание" rules={[{ required: true }]}>
				<TextArea rows={4} />
			</Form.Item>
			{categories && (
				<Form.Item name="companyCategoryId" label="Категория">
					<Select placeholder="Категория">
						{categories.map((o) => (
							<Option key={o.id} value={o.id}>
								{o.name}
							</Option>
						))}
					</Select>
				</Form.Item>
			)}
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCategoryProps {
	editedCategory?: CompanySubCategory;
	categories: CompanyCategory[];
	save(): void;
}
