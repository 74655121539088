import { Button, Divider, Form, Input, InputNumber, message, Select } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHeaders } from '../../helpers/useHeaders';
import { countries, Country } from '../../interfaces/page.interface';
import { countryToCurrency, currencies, ProductCountry } from '../../interfaces/product.interface';

const { Option } = Select;

export function EditCountryProduct({
	product,
	country,
	productId,
	isAuthor,
}: EditCountryProductProps) {
	const [productCountry, setProductCountry] = useState<ProductCountry>();
	const headers = useHeaders();

	useEffect(() => {
		if (product) {
			setProductCountry(product);
		}
	}, [product]);

	const saveData = async (res: ProductCountry) => {
		res = { ...res, country, productId };
		if (!productCountry?.id) {
			const { data } = await axios.post<ProductCountry>(
				'/api-v2/product/country',
				{
					...res,
				},
				headers,
			);
			setProductCountry(data);
		} else {
			await axios.patch<ProductCountry>(
				'/api-v2/product/country/' + productCountry.id,
				{
					...res,
				},
				headers,
			);
		}
		message.success('Сохранено!');
	};

	return (
		<Form
			onFinish={saveData}
			initialValues={
				product ?? {
					currency: countryToCurrency.find((c) => c.country == country)?.currency,
				}
			}
			layout="vertical"
		>
			<Divider />
			<h2>{countries?.find((w) => w.value === country)?.name}</h2>
			<Form.Item name="link" label="Ссылка" rules={isAuthor ? [] : [{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="credit" label="Кредит">
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="price" label="Цена" rules={[{ required: true }]}>
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="oldPrice" label="Старая цена">
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="currency" label="Валюта" rules={[{ required: true }]}>
				<Select placeholder="Валюта">
					{currencies.map((c) => (
						<Option key={c.value} value={c.value}>
							{c.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCountryProductProps {
	product?: ProductCountry;
	country: Country;
	productId: number;
	isAuthor: boolean;
}
