export interface SingleWebsite {
	id: number;
	name: string;
	groupId: number;
	authorName: string;
	authorSurname: string;
	authorPhoto: string;
	authorDescription: string;
	metaTitle: string;
	metaDescription: string;
	html: string | null;
	questions: Question[];
	tags: string[];
	metrikaId?: string;
	yandexVerification?: string;
	googleVerification?: string;
}

export interface Question {
	question: string;
	answer: string;
}

export enum SingleWebsiteType {
  GROUP = 'GROUP',
  BLOG = 'BLOG'
};

export const singleWebsiteTypes = [
	{ name: 'Группа', value: SingleWebsiteType.GROUP },
	{ name: 'Блог', value: SingleWebsiteType.BLOG },
];
