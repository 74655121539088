import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Category, Group, sections, Website } from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';
import { EditGroup } from './edit-group';
import { groupByArray } from '../../helpers/group';

const { Option, OptGroup } = Select;

export function GroupPage() {
	const [data, setData] = useState<Group[]>([]);
	const [categories, setCategories] = useState<Category[]>();
	const [category, setCategory] = useState<Category>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [websites, setWebsites] = useState<Website[]>();
	const [editedGroup, setEditedGroup] = useState<Group | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadCategories();
		loadWebsites();
	}, []);

	useEffect(() => {
		if (category) {
			loadData(category);
		}
	}, [category]);

	const loadCategories = async () => {
		const { data } = await axios.get<Category[]>('/api-v2/category', {
			...headers,
		});
		setCategories(data);
		setCategory(data[0]);
	};

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
	};

	const loadData = async (selectedCategory: Category | undefined) => {
		if (!selectedCategory) {
			return;
		}
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		setData(data.filter((d) => d.categoryId == selectedCategory.id));
	};

	const deleteGroup = async (group: Group) => {
		await axios.delete<any[]>('/api-v2/group/' + group.id, headers);
		loadData(category);
	};

	const selectCategory = (category: Category) => {
		setCategory(category);
		loadData(category);
	};

	const startEdit = (group: Group) => {
		setEditedGroup(group);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedGroup(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedGroup(undefined);
		setIsEdited(false);
		loadData(category);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Категория',
			dataIndex: 'categoryId',
			key: 'categoryId',
			render: (text: string) => {
				return <>{categories && categories.find((c) => c.id === Number(text))?.name}</>;
			},
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteGroup(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						{categories && category && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectCategory(
										categories.find((w) => w.id === value) ?? category
									)
								}
								value={category.name}
								placeholder="Категория"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{Object.entries(groupByArray(categories, 'section')).map(
									(w: any) => (
										<OptGroup label={w[0]}>
											{w[1].map((y: any) => (
												<Option key={y.id} value={y.id}>
													{y.name}
												</Option>
											))}
										</OptGroup>
									)
								)}
							</Select>
						)}
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование группы"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditGroup
						websites={websites}
						editedGroup={editedGroup}
						save={saveEdit}
						categories={categories ?? []}
					/>
				)}
			</Drawer>
		</>
	);
}
