import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Tags } from '../../components';
import Uploader from '../../components/uploader/uploader';
import { groupByArray } from '../../helpers/group';
import { useHeaders } from '../../helpers/useHeaders';
import { Category, Group, GroupForPage, Website } from '../../interfaces/page.interface';
import { CompanySubCategory } from '../../interfaces/post.interface';
import { EditWebsiteGroup } from './edit-websitegroup';

const { Option, OptGroup } = Select;

export function EditGroup({ editedGroup, save, categories, websites }: EditGroupProps) {
	const headers = useHeaders();
	const [group, setGroup] = useState<GroupForPage>();
	const [form] = Form.useForm();
	const [subCategories, setSubCategories] = useState<CompanySubCategory[]>([]);

	useEffect(() => {
		if (editedGroup?.id) {
			loadGroup();
		}
		loadCategories();
	}, [editedGroup]);

	const loadGroup = async () => {
		const { data } = await axios.get<GroupForPage>('/api-v2/group/' + editedGroup?.id, headers);
		setGroup(data);
		form.setFieldsValue(data);
	};

	const loadCategories = async () => {
		const { data: sub } = await axios.get<CompanySubCategory[]>('/api-v2/company-subcategory');
		setSubCategories(sub);
	};

	const saveData = async (res: Group) => {
		if (!editedGroup?.id) {
			await axios.post<Group>(
				'/api-v2/group',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Group>(
				'/api-v2/group/' + editedGroup.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<>
			<Form onFinish={saveData} form={form} layout="vertical">
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="name" label="Название" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="title" label="Заголовок" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="image" noStyle getValueFromEvent={(e) => e}>
							<Uploader entity="products" text="Изображение" />
						</Form.Item>
						<Form.Item name="alternateAlias" label="Альтернативный Alias">
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="categoryId" label="Категория" rules={[{ required: true }]}>
							<Select placeholder="Категория">
								{Object.entries(groupByArray(categories, 'section')).map(
									(w: any) => (
										<OptGroup label={w[0]}>
											{w[1].map((y: any) => (
												<Option key={y.id} value={y.id}>
													{y.name}
												</Option>
											))}
										</OptGroup>
									)
								)}
							</Select>
						</Form.Item>
						<Form.Item name="tags" label="Тэги">
							<Tags />
						</Form.Item>
						<Form.Item name="order" label="Порядок">
							<InputNumber />
						</Form.Item>
						<Form.Item name="cpcCost" label="Цена клика">
							<InputNumber min={0} />
						</Form.Item>
					</Col>
					<Col>
						{subCategories && (
							<Form.Item name="companySubCategoryId" label="Подкатегории">
								<Select placeholder="Подкатегории" optionFilterProp="children">
									{subCategories.map((c) => (
										<Option key={c.id} value={c.id}>
											{c.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
					</Col>
					<Col>
						<Button
							onClick={() =>
								form.setFields([{ name: 'companySubCategoryId', value: null }])
							}
						>
							Очистить
						</Button>
					</Col>
				</Row>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Сохранить
					</Button>
				</Form.Item>
			</Form>
			{group &&
				websites?.map((w) => {
					const g = group.websiteGroup.find((wg) => wg.websiteId === w.id);
					return (
						<EditWebsiteGroup
							key={w.id}
							websites={websites}
							group={g}
							websiteId={w.id}
							groupId={editedGroup?.id}
						/>
					);
				})}
		</>
	);
}

export interface EditGroupProps {
	categories: Category[];
	editedGroup?: Group;
	websites?: Website[];
	save(): void;
}
