import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHeaders } from '../../helpers/useHeaders';
import { AuthorsPage, Category, Group, sections, Website } from '../../interfaces/page.interface';
import { Event } from '../../interfaces/event.interface';
import { Author, Company, OrgType, orgTypes } from '../../interfaces/post.interface';
import moment from 'moment';

const { Option } = Select;

export function EditEvent({ editedEvent, save, websites }: EditPostProps) {
	const headers = useHeaders();
	const [authors, setAuthors] = useState<Author[]>([]);
	const [groups, setGroups] = useState<Group[]>();
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [companies, setCompanies] = useState<Company[]>();

	useEffect(() => {
		loadGroups();
		loadComapanies(orgType);
		loadAuthors();
	}, []);

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadComapanies(type);
	};

	const loadAuthors = async (website?: Website) => {
		const { data } = await axios.get<AuthorsPage>('/api-v2/author/all', {
			...headers,
			params: {
				skip: 0,
				take: 1000,
				website: website?.name,
				date: new Date(),
			},
		});
		setAuthors(data.authors);
	};

	const loadComapanies = async (type: OrgType) => {
		const { data } = await axios.get<Company[]>('/api-v2/company/type/' + type, headers);
		setCompanies(data);
	};

	const loadGroups = async () => {
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		setGroups(data);
	};

	const saveData = async (res: Event) => {
		if (!editedEvent?.id) {
			await axios.post<Event>(
				'/api-v2/event',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/event/' + editedEvent.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form
			onFinish={saveData}
			initialValues={{
				...editedEvent,
				date: moment(editedEvent?.date),
			}}
			layout="vertical"
		>
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="name" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="description" label="Описание" rules={[{ required: true }]}>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="websiteId" label="Сайт" rules={[{ required: true }]}>
						<Select placeholder="Сайт">
							{websites.map((w) => (
								<Option key={w.id} value={w.id}>
									{w.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					{groups && (
						<Form.Item name="groupId" label="Группа">
							<Select
								showSearch
								placeholder="Категория"
								optionFilterProp="name"
								filterOption={(inputValue: string, option?: any) =>
									option.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{groups.map((g) => (
									<Option key={g.id} value={g.id}>
										{g.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
				</Col>
				<Col>
					<Form.Item name="link" label="Ссылка" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="date" label="Дата мероприятия">
						<DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
					</Form.Item>
					<Select
						style={{ width: 200 }}
						onSelect={(value: any) => selectType(value)}
						value={orgType}
						placeholder="Тип компании"
					>
						{orgTypes.map((w) => (
							<Option key={w.value} value={w.value}>
								{w.name}
							</Option>
						))}
					</Select>
					{companies && (
						<Form.Item name="companyId" label="Компания">
							<Select
								placeholder="Компания"
								showSearch
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{companies.map((company) => (
									<Option key={company.id} value={company.id}>
										{company.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					{authors && (
						<Form.Item name="authorId" label="Автор">
							<Select
								placeholder="Автор"
								showSearch
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{authors.map((author) => (
									<Option key={author.id} value={author.id}>
										{author.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					<Form.Item name="isVideo" valuePropName="checked">
						<Checkbox>В записи</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditPostProps {
	websites: Website[];
	editedEvent?: Event;
	save(): void;
}
