import { Company } from './post.interface';

export type Code = {
	id: number;
	code: string;
	title: string;
	description: string;
	link: string;
	disclaimer: string | null;
	dateStart: Date;
	dateEnd: Date;
	value: number | null;
	codeType: CodeType;
	clicks: number;
	companyId: number;
	company: Company;
	codeOnWebsite: CodeOnWebsite[];
	admitadId?: number;
	advcakeId?: string;
	hidden?: boolean;
	likes: number;
	dislikes: number;
};

export type CodeOnWebsite = {
	codeId: number;
	websiteId: number;
};

export enum CodeType {
	PERCENT = 'PERCENT',
	RUB = 'RUB',
	KZT = 'KZT',
	BYN = 'BYN',
	BONUS = 'BONUS',
}

export const codeTypes = [
	{ name: '%', value: CodeType.PERCENT },
	{ name: '₽', value: CodeType.RUB },
	{ name: '₸', value: CodeType.KZT },
	{ name: 'BYN', value: CodeType.BYN },
	{ name: 'BONUS', value: CodeType.BONUS },
];
