import { Button, Checkbox, Col, Divider, Form, Input, message, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import Quill from '../../components/quill/quill';
import { useHeaders } from '../../helpers/useHeaders';
import { Website, WebsiteGroup } from '../../interfaces/page.interface';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

export function EditWebsiteGroup({ group, websites, websiteId, groupId }: EditWebsiteGroupProps) {
	const headers = useHeaders();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(group);
	}, [group]);

	const saveData = async (res: WebsiteGroup) => {
		if (!res.questions) {
			res.questions = undefined;
		}
		if (!groupId) {
			return;
		}
		res = { ...res, websiteId, groupId };
		if (!group?.id) {
			await axios.post<WebsiteGroup>(
				'/api-v2/group/website',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<WebsiteGroup>(
				'/api-v2/group/website/' + group.id,
				{
					...res,
				},
				headers
			);
		}
		message.success('Сохранено!');
	};

	return (
		<Form onFinish={saveData} form={form} layout="vertical">
			<Divider />
			<h2>{websites?.find((w) => w.id === websiteId)?.name}</h2>
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="metaTitle" label="metaTitle" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="metaDescription"
						label="metaDescription"
						rules={[{ required: true }]}
					>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="h1" label="Заголовок h1">
						<Input />
					</Form.Item>
					<Form.Item name="h2" label="Заголовок h2">
						<Input />
					</Form.Item>
					<Form.Item name="isMainOnly" valuePropName="checked">
						<Checkbox>Отображать только на главном домене</Checkbox>
					</Form.Item>
					<Form.List name="questions">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => (
									<div
										key={key}
										style={{
											display: 'flex',
											gap: 8,
											flexDirection: 'column',
										}}
									>
										<div
											key={key}
											style={{
												display: 'flex',
												gap: 8,
												alignItems: 'center',
											}}
										>
											<Form.Item
												{...restField}
												name={[name, 'question']}
												style={{ marginBottom: 0, width: '100%' }}
												rules={[{ required: true }]}
											>
												<Input
													placeholder="Вопрос"
													style={{ width: '100%' }}
												/>
											</Form.Item>
											<MinusCircleOutlined onClick={() => remove(name)} />
										</div>
										<Form.Item
											{...restField}
											style={{ width: '100%' }}
											name={[name, 'answer']}
											rules={[{ required: true }]}
										>
											<TextArea rows={4} style={{ width: '100%' }} />
										</Form.Item>
									</div>
								))}
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => add()}
										block
										icon={<PlusOutlined />}
									>
										Добавить вопрос
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Col>
				<Col span={14}>
					<Form.Item name="html" label="Текст">
						<Quill />
					</Form.Item>
					<Form.Item name="html2" label="Второй текст">
						<Quill />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditWebsiteGroupProps {
	group?: WebsiteGroup;
	websites?: Website[];
	websiteId: number;
	groupId?: number;
}
