import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import Uploader from '../../components/uploader/uploader';
import { useHeaders } from '../../helpers/useHeaders';
import { CompanyCategory } from '../../interfaces/post.interface';

export function EditCompanyCategory({ editedCategory, save }: EditCategoryProps) {
	const headers = useHeaders();

	const saveData = async (res: CompanyCategory) => {
		if (!editedCategory?.id) {
			await axios.post<CompanyCategory>(
				'/api-v2/company-category',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<CompanyCategory>(
				'/api-v2/company-category/' + editedCategory.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={editedCategory} layout="vertical">
			<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="description" label="Описание" rules={[{ required: true }]}>
				<TextArea rows={4} />
			</Form.Item>
			<Form.Item name="image" noStyle getValueFromEvent={(e) => e}>
				<Uploader entity="categories" text="Изображение" />
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCategoryProps {
	editedCategory?: CompanyCategory;
	save(): void;
}
