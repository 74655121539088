import { Button, Form, Input, Space, Tag } from 'antd';
import axios from 'axios';
import { useHeaders } from '../../helpers/useHeaders';
import { Characteristic, Product, Review } from '../../interfaces/product.interface';

const templates = [
	{ name: 'Длительность', value: '6 месяцев' },
	{ name: 'Сложность', value: 'С нуля' },
	{ name: 'Документ об окончании', value: 'Диплом' },
];

export function EditCharacteristic({ characteristic, productId, save }: EditCharacteristicProps) {
	const headers = useHeaders();
	const [form] = Form.useForm();

	const saveData = async () => {
		const res: Characteristic = form.getFieldsValue();
		res.productId = productId;
		if (!characteristic?.id) {
			await axios.post<Characteristic>(
				'/api-v2/product/characteristic',
				{
					...res,
				},
				headers,
			);
		} else {
			await axios.patch<Product>(
				'/api-v2/product/characteristic/' + characteristic.id,
				{
					...res,
				},
				headers,
			);
		}
		form.resetFields();
		save();
	};

	const deleteCharacteristic = async () => {
		if (characteristic) {
			await axios.delete<Review>(
				'/api-v2/product/characteristic/' + characteristic.id,
				headers,
			);
		}
		save();
	};

	const setTemplate = (template: { name: string; value: string }) => {
		form.setFields([
			{ name: 'name', value: template.name },
			{ name: 'value', value: template.value },
		]);
	};

	return (
		<>
			<Form form={form} initialValues={characteristic} layout="horizontal">
				<Space align="end">
					<Form.Item name="name" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="value" label="Значение" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item>
						<Button type="primary" onClick={saveData}>
							Сохранить
						</Button>
					</Form.Item>
					{characteristic && (
						<Form.Item>
							<Button type="ghost" onClick={deleteCharacteristic}>
								Удалить
							</Button>
						</Form.Item>
					)}
				</Space>
				{!characteristic &&
					templates.map((t) => (
						<Tag
							style={{ cursor: 'pointer' }}
							onClick={() => setTemplate(t)}
							key={t.name}
						>
							{t.name}
						</Tag>
					))}
				<br />
				<br />
			</Form>
		</>
	);
}

export interface EditCharacteristicProps {
	characteristic?: Characteristic;
	productId: number;
	save(): void;
}
