import { RecoilRoot } from 'recoil';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ProtectedRoute } from './protected-route';
import { Login } from './pages/login/login';
import 'antd/dist/antd.min.css';
import { Categories } from './pages/categories/categories';
import { Blog } from './pages/blog/blog';
import { GroupPage } from './pages/groups/groups';
import { Companies } from './pages/companies/companies';
import { ProductPage } from './pages/products/products';
import { Authors } from './pages/authors/authors';
import { CodePage } from './pages/codes/codes';
import { AddressPage } from './pages/address/address';
import { MonoPage } from './pages/mono/mono';
import { CompanyCategories } from './pages/company-categories/company-categories';
import { CompanySubCategories } from './pages/company-subcategories/company-subcategories';
import { PartnersPage } from './pages/partners/partners';
import { EventsPage } from './pages/events/events';
import { BlogCategories } from './pages/blog-categories/categories';

export function App() {
	return (
		<RecoilRoot>
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route
						path="/"
						element={
							<ProtectedRoute>
								<Categories />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/blog-categories"
						element={
							<ProtectedRoute>
								<BlogCategories />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/blog"
						element={
							<ProtectedRoute>
								<Blog />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/groups"
						element={
							<ProtectedRoute>
								<GroupPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/companies"
						element={
							<ProtectedRoute>
								<Companies />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/company-categories"
						element={
							<ProtectedRoute>
								<CompanyCategories />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/company-subcategories"
						element={
							<ProtectedRoute>
								<CompanySubCategories />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/authors"
						element={
							<ProtectedRoute>
								<Authors />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/products"
						element={
							<ProtectedRoute>
								<ProductPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/codes"
						element={
							<ProtectedRoute>
								<CodePage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/events"
						element={
							<ProtectedRoute>
								<EventsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/mono"
						element={
							<ProtectedRoute>
								<MonoPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/addresses"
						element={
							<ProtectedRoute>
								<AddressPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/partners"
						element={
							<ProtectedRoute>
								<PartnersPage />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</RecoilRoot>
	);
}
