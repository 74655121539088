import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHeaders } from '../../helpers/useHeaders';
import { Code, codeTypes } from '../../interfaces/codes.interface';
import { Category, Website } from '../../interfaces/page.interface';
import { Company, OrgType, orgTypes, Post } from '../../interfaces/post.interface';

const { Option } = Select;

export function EditCode({ editedCode, save, websites }: EditCodeProps) {
	const headers = useHeaders();
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [companies, setCompanies] = useState<Company[]>();
	const [form] = Form.useForm();

	useEffect(() => {
		loadComapanies(orgType);
	}, []);

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadComapanies(type);
	};

	const loadComapanies = async (type: OrgType) => {
		const { data } = await axios.get<Company[]>('/api-v2/company/type/' + type, headers);
		setCompanies(data);
		loadData();
	};

	const loadData = async () => {
		if (!editedCode?.id) {
			return;
		}
		const { data } = await axios.get<Code>('/api-v2/code/' + editedCode.id, {
			...headers,
		});
		form.setFieldsValue({
			...data,
			dateStart: moment(editedCode?.dateStart),
			dateEnd: moment(editedCode?.dateEnd),
			websiteIds: editedCode?.codeOnWebsite.map((w) => w.websiteId),
		});
	};

	const saveData = async (res: Post) => {
		if (!editedCode?.id) {
			await axios.post<Post>(
				'/api-v2/code',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/code/' + editedCode.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} form={form} layout="vertical">
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="title" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="code" label="Код" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="description" label="Описание" rules={[{ required: true }]}>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="link" label="link" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="disclaimer" label="Дисклеймер">
						<TextArea rows={4} />
					</Form.Item>
					{websites && (
						<Form.Item name="websiteIds" label="Сайты">
							<Select
								showSearch
								placeholder="Сайты"
								mode="multiple"
								optionFilterProp="name"
								filterOption={(inputValue: string, option?: any) =>
									option.name?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
									0
								}
							>
								{websites.map((g) => (
									<Option key={g.id} value={g.id}>
										{g.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
				</Col>
				<Col span={14}>
					<Form.Item name="dateStart" label="Дата начала" rules={[{ required: true }]}>
						<DatePicker />
					</Form.Item>
					<Form.Item name="dateEnd" label="Дата окончания" rules={[{ required: true }]}>
						<DatePicker />
					</Form.Item>
					<Form.Item name="value" label="Значение скидки" rules={[{ required: true }]}>
						<InputNumber />
					</Form.Item>
					<Form.Item name="codeType" label="Тип скидки" rules={[{ required: true }]}>
						<Select placeholder="Тип скидки">
							{codeTypes.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="hidden" valuePropName="checked">
						<Checkbox>Скрыт</Checkbox>
					</Form.Item>
					<Select
						style={{ width: 200 }}
						onSelect={(value: any) => selectType(value)}
						value={orgType}
						placeholder="Тип компании"
					>
						{orgTypes.map((w) => (
							<Option key={w.value} value={w.value}>
								{w.name}
							</Option>
						))}
					</Select>
					{companies && (
						<Form.Item name="companyId" label="Компания">
							<Select
								placeholder="Компания"
								showSearch
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{companies.map((company) => (
									<Option key={company.id} value={company.id}>
										{company.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					<Form.Item name="clicks" label="Клики">
						<InputNumber min={0} />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCodeProps {
	websites: Website[];
	editedCode?: Code;
	save(): void;
}
