import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Tags } from '../../components';
import Uploader from '../../components/uploader/uploader';
import { groupByArray } from '../../helpers/group';
import { useHeaders } from '../../helpers/useHeaders';
import { countries, Group, sections, Website, Category } from '../../interfaces/page.interface';
import { Company, OrgType, orgTypes } from '../../interfaces/post.interface';
import { Product, ProductWithReviewsAndWebsite } from '../../interfaces/product.interface';
import { EditCharacteristic } from './edit-characteristic';
import { EditCountryProduct } from './edit-coutryproduct';
import { EditWebsiteProduct } from './edit-websiteproduct';

const { Option, OptGroup } = Select;

export function EditProduct({ editedProduct, save, websites, authorId }: EditProductProps) {
	const headers = useHeaders();
	const [orgType, setOrgType] = useState<OrgType>();
	const [product, setProduct] = useState<ProductWithReviewsAndWebsite>();
	const [groups, setGroups] = useState<Group[]>();
	const [categories, setCategories] = useState<Category[]>();
	const [companies, setCompanies] = useState<Company[]>();
	const [form] = Form.useForm();

	useEffect(() => {
		loadGroups();
		loadComapanies(OrgType.SCHOOL);
		loadCategories();
	}, []);

	useEffect(() => {
		loadProduct();
	}, [editedProduct]);

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadComapanies(type);
	};

	const loadCategories = async () => {
		const { data } = await axios.get<Category[]>('/api-v2/category', headers);
		setCategories(data);
	};

	const loadComapanies = async (type: OrgType) => {
		const { data } = await axios.get<Company[]>('/api-v2/company/type/' + type, headers);
		setCompanies(data);
	};

	const loadGroups = async () => {
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		setGroups(data);
	};

	const loadProduct = async () => {
		const { data } = await axios.get<ProductWithReviewsAndWebsite>(
			'/api-v2/product/' + editedProduct?.id,
			headers
		);

		setProduct({ ...data, groupIds: data.groups.map((g) => g.groupId) });
		form.setFieldsValue({
			...data,
			groupIds: data.groups.map((g) => g.groupId),
		});
		setOrgType(data.company.orgType);
		loadComapanies(data.company.orgType);
	};

	const saveData = async (res: Product) => {
		if (authorId) {
			res.authorId = authorId;
		}
		if (!editedProduct?.id) {
			await axios.post<Product>(
				'/api-v2/product',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Product>(
				'/api-v2/product/' + editedProduct.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<>
			<Form onFinish={saveData} form={form} layout="vertical">
				<Row gutter={16}>
					<Col span={10}>
						<Form.Item name="title" label="Заголовок" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="description" label="Описание">
							<TextArea rows={4} />
						</Form.Item>
						<Row gutter={16}>
							{/* <Col span={12}>
								<Form.Item name="image" noStyle getValueFromEvent={(e) => e}>
									<Uploader entity="products" text="Изображение" />
								</Form.Item>
							</Col>*/}
							<Col span={12}>
								<Form.Item name="bigImage" noStyle getValueFromEvent={(e) => e}>
									<Uploader entity="products" text="Большое изображение" />
								</Form.Item>
							</Col>
						</Row> 
						{product &&
							product.characteristics.map((c) => (
								<EditCharacteristic
									key={c.id}
									characteristic={c}
									productId={product.id}
									save={loadProduct}
								/>
							))}
						{product && (
							<EditCharacteristic productId={product.id} save={loadProduct} />
						)}
					</Col>
					<Col span={14}>
						<Form.Item name="advantages" label="Преимущества">
							<TextArea rows={4} />
						</Form.Item>
						<Form.Item name="disAdvantages" label="Недостатки">
							<TextArea rows={4} />
						</Form.Item>
						<Form.Item name="tags" label="Теги">
							<Tags />
						</Form.Item>
						{groups && categories && (
							<Form.Item name="groupIds" label="Группы" rules={[{ required: true }]}>
								<Select
									placeholder="Группы"
									mode="multiple"
									optionFilterProp="children"
								>
									{Object.entries(groupByArray(groups, 'categoryId')).map(
										(w: any) => (
											<OptGroup
												label={
													categories.find((c) => c.id == w[0])?.section
												}
											>
												{w[1].map((y: any) => (
													<Option key={y.id} value={y.id}>
														{y.name}
													</Option>
												))}
											</OptGroup>
										)
									)}
								</Select>
							</Form.Item>
						)}
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) => selectType(value)}
							value={orgType}
							placeholder="Тип компании"
						>
							{orgTypes.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
						{companies && orgType && (
							<Form.Item name="companyId" label="Компания">
								<Select
									placeholder="Компания"
									showSearch
									optionFilterProp="children"
									filterOption={(inputValue: string, option?: any) =>
										option?.children
											?.toLowerCase()
											.indexOf(inputValue.toLowerCase()) >= 0
									}
								>
									{companies.map((company) => (
										<Option key={company.id} value={company.id}>
											{company.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
						<Form.Item name="alias" label="Alias">
							<Input />
						</Form.Item>
						<Form.Item name="isHidden" valuePropName="checked">
							<Checkbox>Скрыт</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Сохранить
					</Button>
				</Form.Item>
			</Form>
			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
				{product &&
					countries?.map((c) => {
						const c2 = product.productCountry?.find((pc) => pc.country === c.value);
						return (
							<div style={{ width: 'calc(33% - 16px)' }}>
								<EditCountryProduct
									isAuthor={!!authorId}
									key={c.value}
									product={c2}
									productId={product.id}
									country={c.value}
								/>
							</div>
						);
					})}
			</div>
			<div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
				{product &&
					websites?.map((w) => {
						const p = product.websiteProduct?.find((wg) => wg.websiteId === w.id);
						return (
							<div style={{ width: 'calc(50% - 8px)' }}>
								<EditWebsiteProduct
									key={w.id}
									websites={websites}
									product={p}
									productId={product.id}
									websiteId={w.id}
								/>
							</div>
						);
					})}
			</div>
		</>
	);
}

export interface EditProductProps {
	editedProduct?: ProductWithReviewsAndWebsite;
	websites?: Website[];
	save(): void;
	authorId?: number;
}
