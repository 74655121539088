import { Button, Divider, Form, InputNumber, message } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Quill from '../../components/quill/quill';
import { useHeaders } from '../../helpers/useHeaders';
import { Website } from '../../interfaces/page.interface';
import { WebsiteProduct } from '../../interfaces/product.interface';

export function EditWebsiteProduct({
	product,
	websites,
	websiteId,
	productId,
}: EditWebsiteProductProps) {
	const headers = useHeaders();
	const [productWebsite, setProductWebsite] = useState<WebsiteProduct>();

	useEffect(() => {
		if (product) {
			setProductWebsite(product);
		}
	}, [product]);

	const saveData = async (res: WebsiteProduct) => {
		res = { ...res, websiteId: websiteId, productId };
		if (!productWebsite?.id) {
			const { data } = await axios.post<WebsiteProduct>(
				'/api-v2/product/website',
				{
					...res,
				},
				headers,
			);
			setProductWebsite(data);
		} else {
			await axios.patch<WebsiteProduct>(
				'/api-v2/product/website/' + productWebsite.id,
				{
					...res,
				},
				headers,
			);
		}
		message.success('Сохранено!');
	};

	return (
		<Form onFinish={saveData} initialValues={product} layout="vertical">
			<Divider />
			<h2>{websites?.find((w) => w.id === websiteId)?.name}</h2>
			<Form.Item name="initialRating" label="Рейтинг" rules={[{ required: true }]}>
				<InputNumber min={1} max={5} step={0.1} />
			</Form.Item>
			<Form.Item name="html" label="Текст">
				<Quill />
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditWebsiteProductProps {
	product?: WebsiteProduct;
	websites?: Website[];
	websiteId: number;
	productId: number;
}
