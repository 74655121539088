import { Button, Form, Input, InputNumber, Select } from 'antd';
import axios from 'axios';
import { useHeaders } from '../../helpers/useHeaders';
import { Category, sections } from '../../interfaces/page.interface';

const { Option } = Select;

export function EditCategory({ editedCategory, save }: EditCategoryProps) {
	const headers = useHeaders();

	const saveData = async (res: Category) => {
		if (!editedCategory?.id) {
			await axios.post<Category>(
				'/api-v2/category',
				{
					...res,
				},
				headers,
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/category/' + editedCategory.id,
				{
					...res,
				},
				headers,
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={editedCategory} layout="vertical">
			<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="order" label="Порядок">
				<InputNumber />
			</Form.Item>
			<Form.Item name="section" label="Секция" rules={[{ required: true }]}>
				<Select placeholder="Категория">
					{sections.map((c) => (
						<Option key={c.value} value={c.value}>
							{c.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCategoryProps {
	editedCategory?: Category;
	save(): void;
}
