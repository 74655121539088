import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditCategory } from './edit-category';
import { Category, Section, sections } from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';

const { Option } = Select;

export function Categories() {
	const [data, setData] = useState<Category[]>([]);
	const [selectedSection, setSelectedSection] = useState<Section>(Section.COURSES);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [editedCategory, setEditedCategory] = useState<Category | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadData(selectedSection);
	}, []);

	const loadData = async (section: Section) => {
		const { data } = await axios.get<any[]>('/api-v2/category/section/' + section, headers);
		setData(data);
	};

	const deleteCategory = async (category: Category) => {
		await axios.delete<any[]>('/api-v2/category/' + category.id, headers);
		loadData(selectedSection);
	};

	const selectCategory = (section: Section) => {
		setSelectedSection(section);
		loadData(section);
	};

	const startEdit = (category: Category) => {
		setEditedCategory(category);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedCategory(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedCategory(undefined);
		setIsEdited(false);
		loadData(selectedSection);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Секция',
			dataIndex: 'section',
			key: 'section',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteCategory(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						<Select
							style={{ width: 200 }}
							showSearch
							onSelect={(value: Section) => selectCategory(value)}
							value={selectedSection}
							placeholder="Категория"
							optionFilterProp="children"
							filterOption={(inputValue: string, option?: any) =>
								option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
								0
							}
						>
							{sections.map((s) => (
								<Option key={s.value} value={s.value}>
									{s.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>

				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование категории"
				placement="right"
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && <EditCategory editedCategory={editedCategory} save={saveEdit} />}
			</Drawer>
		</>
	);
}
