import { FC } from 'react';
import ReactQuill from 'react-quill';
import { QuillProps } from './quill.props';
import 'react-quill/dist/quill.snow.css';

const Quill: FC<QuillProps> = ({ value, onChange, style }: QuillProps) => {
	return (
		<ReactQuill
			theme="snow"
			value={value ?? ''}
			onChange={onChange}
			style={style}
			modules={{
				toolbar: [
					[{ header: [1, 2, 3, 4, 5, false] }],
					['bold', 'italic', 'underline'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ script: 'sub' }, { script: 'super' }],
					[{ indent: '-1' }, { indent: '+1' }],
					['code-block', 'link'],
					['clean'],
				],
			}}
		/>
	);
};
export default Quill;
