import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Quill from '../../components/quill/quill';
import Uploader from '../../components/uploader/uploader';
import { useHeaders } from '../../helpers/useHeaders';
import { Category, Group, sections, Website } from '../../interfaces/page.interface';
import { Post, PostCategory } from '../../interfaces/post.interface';
import { SingleWebsite } from '../../interfaces/single-website.interface';

const { Option } = Select;

export function EditPost({ editedPost, save, websites, singleWebsites }: EditPostProps) {
	const headers = useHeaders();
	const [groups, setGroups] = useState<Group[]>();
	const [categories, setCategories] = useState<PostCategory[]>([]);

	useEffect(() => {
		loadGroups();
		loadCategories();
	}, []);

	const loadCategories = async () => {
		const { data } = await axios.get<any[]>('/api-v2/post-category/find', {
			...headers,
		});
		setCategories(data);
	};

	const loadGroups = async () => {
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		setGroups(data);
	};

	const saveData = async (res: Post) => {
		if (!editedPost?.id) {
			await axios.post<Post>(
				'/api-v2/blog',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/blog/' + editedPost.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={editedPost} layout="vertical">
			<Row gutter={16}>
				<Col span={10}>
					<Form.Item name="title" label="Название" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="metaTitle" label="MetaTitle" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="metaDescription"
						label="MetaDescription"
						rules={[{ required: true }]}
					>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="websiteId" label="Сайт">
						<Select placeholder="Сайт">
							{websites.map((w) => (
								<Option key={w.id} value={w.id}>
									{w.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="singleWebsiteId" label="Сайт моно">
						<Select placeholder="Сайт моно">
							{singleWebsites.map((w) => (
								<Option key={w.id} value={w.id}>
									{w.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="postCategoryId" label="Категория">
						<Select
							style={{ width: 200 }}
							showSearch
							placeholder="Категория"
							optionFilterProp="children"
							filterOption={(inputValue: string, option?: any) =>
								option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
								0
							}
						>
							{categories.map((s) => (
								<Option key={s.id} value={s.id}>
									{s.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					{groups && (
						<Form.Item name="groupId" label="Группа">
							<Select
								showSearch
								placeholder="Категория"
								optionFilterProp="name"
								filterOption={(inputValue: string, option?: any) =>
									option.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{groups.map((g) => (
									<Option key={g.id} value={g.id}>
										{g.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					)}
					<Form.Item name="contentPreview" label="Превью" rules={[{ required: true }]}>
						<TextArea rows={4} />
					</Form.Item>
					<Form.Item name="image" noStyle getValueFromEvent={(e) => e}>
						<Uploader entity="products" text="Изображение" />
					</Form.Item>
					<Form.Item name="price" label="Цена">
						<InputNumber />
					</Form.Item>
					<Form.Item name="oldPrice" label="Старая цена">
						<InputNumber />
					</Form.Item>
					<Form.Item name="link" label="Ссылка">
						<Input />
					</Form.Item>
				</Col>
				<Col span={14}>
					<Form.Item name="content" label="Текст">
						<Quill style={{ height: '80vh' }} />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditPostProps {
	websites: Website[];
	singleWebsites: SingleWebsite[];
	editedPost?: Post;
	save(): void;
}
