import { Button, Checkbox, DatePicker, Form } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useHeaders } from '../../helpers/useHeaders';

export function EditPartner({
	productId,
	groupId,
	partnerEndDate,
	isCpc,
	placementEndDate,
	save,
}: EditPartnerProps) {
	const headers = useHeaders();
	console.log(isCpc);
	const savePartner = async (res: any) => {
		res.productId = productId;
		res.groupId = groupId;
		await axios.patch(
			'/api-v2/group/updatePartnerDate',
			{
				...res,
			},
			headers,
		);
		save();
	};

	return (
		<>
			<Form
				onFinish={savePartner}
				layout="vertical"
				initialValues={{
					partnerEndDate: moment(partnerEndDate),
					placementEndDate: moment(placementEndDate),
					isCpc,
				}}
			>
				<Form.Item name="partnerEndDate" label="Партнёр раздела до">
					<DatePicker />
				</Form.Item>
				<Form.Item name="placementEndDate" label="Размещение в разделе до">
					<DatePicker />
				</Form.Item>
				<Form.Item name="isCpc" valuePropName="checked">
					<Checkbox>CPC размещение</Checkbox>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Сохранить
					</Button>
				</Form.Item>
			</Form>
		</>
	);
}

export interface EditPartnerProps {
	productId: number;
	groupId: number;
	partnerEndDate: Date;
	placementEndDate: Date;
	isCpc?: boolean;
	save(): void;
}
