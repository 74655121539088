import { Route, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { LayoutComponent } from './layout/layout';
import { authState } from './states/app.state';

export const ProtectedRoute = ({ children }: any) => {
	const navigate = useNavigate();
	const setAuth = useSetRecoilState(authState);
	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			setAuth({ isAuthed: true, token });
		} else {
			navigate('/login');
		}
	}, []);

	return <LayoutComponent>{children}</LayoutComponent>;
};
