import { Button, Col, DatePicker, Drawer, Row, Select, Space, Steps, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
	PlusOutlined,
	DeleteOutlined,
	EyeOutlined,
	PercentageOutlined,
	AimOutlined,
	EditOutlined,
	MessageOutlined,
	StarOutlined,
} from '@ant-design/icons';
import {
	AuthorWithProducts,
	Category,
	Country,
	Group,
	GroupForPage,
	ProductGroupInfo,
	sections,
	Website,
	Location,
	countries,
} from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';
import { Product, ProductWithReviewsAndWebsite } from '../../interfaces/product.interface';
import { addDays, format, startOfMonth } from 'date-fns';
import moment from 'moment';
import { EditReview } from './edit-review';
import { EditProduct } from './edit-product';
import { EditPartner } from './edit-partner';
import { groupByArray } from '../../helpers/group';
import { ImportProducts } from './import-products';

const { Option, OptGroup } = Select;
const { Step } = Steps;

export function ProductPage() {
	const [data, setData] = useState<GroupForPage>();
	const [offlineProducts, setOfflineProducts] = useState<ProductGroupInfo[]>([]);
	const [onlineProducts, setOnlineProducts] = useState<ProductGroupInfo[]>([]);
	const [freeProducts, setFreeProducts] = useState<ProductGroupInfo[]>([]);
	const [authorProducts, setAuthorProducts] = useState<AuthorWithProducts[]>([]);
	const [categories, setCategories] = useState<Category[]>();
	const [category, setCategory] = useState<Category>();
	const [groups, setGroups] = useState<Group[]>();
	const [group, setGroup] = useState<Group>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [websites, setWebsites] = useState<Website[]>();
	const [website, setWebsite] = useState<Website>();
	const [country, setCountry] = useState<Country>(Country.RU);
	const [locations, setLocations] = useState<Location[]>();
	const [editedProduct, setEditedProduct] = useState<ProductWithReviewsAndWebsite | undefined>();
	const [isEditedReviews, setIsEditedReviews] = useState<boolean>(false);
	const [editedReviews, setEditedReviews] = useState<ProductWithReviewsAndWebsite>();
	const [stats, setStats] = useState<{ clicks: number; conversion: number; views: number }>();
	const [date, setDate] = useState<Date>(new Date());
	const [isImport, setIsImport] = useState<boolean>(false);
	const [editedPartner, setEditedPartner] = useState<{
		productId: number;
		groupId: number;
		partnerEndDate: Date;
		placementEndDate: Date;
		isCpc?: boolean;
	}>();
	const headers = useHeaders();

	const sortProducts = (a: ProductGroupInfo, b: ProductGroupInfo) => {
		return a.product.websiteProduct[0]?.initialRating >
			b.product.websiteProduct[0]?.initialRating
			? -1
			: 1;
	};

	useEffect(() => {
		loadLocations(country);
	}, []);

	useEffect(() => {
		loadCategories();
		loadWebsites();
	}, []);

	useEffect(() => {
		if (category) {
			loadGroups(category);
		}
	}, [category]);

	useEffect(() => {
		if (group && website && date) {
			loadData(group, website, date);
			loadAuthors(group);
		}
	}, [group, website, date]);

	const loadLocations = async (selectedCountry: Country) => {
		const { data } = await axios.get<Location[]>(
			'/api-v2/location/country/' + selectedCountry,
			headers
		);
		setLocations(data);
	};

	const selectCountry = (selectedCountry: Country) => {
		setCountry(selectedCountry);
		loadLocations(selectedCountry);
	};

	const loadCategories = async () => {
		const { data } = await axios.get<Category[]>('/api-v2/category', {
			...headers,
		});
		setCategories(data);
		setCategory(data[0]);
	};

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
		setWebsite(websitesData[0]);
	};

	const loadGroups = async (selectedCategory: Category | undefined) => {
		if (!selectedCategory) {
			return;
		}
		const { data } = await axios.get<Group[]>('/api-v2/group/all', {
			params: {
				sections: sections.map((s) => s.value),
			},
			...headers,
		});
		const res = data.filter((d) => d.categoryId == selectedCategory.id);
		setGroups(res);
		setGroup(res[0]);
	};

	const loadData = async (group?: Group, website?: Website, date?: Date, city?: string) => {
		if (!group) {
			return;
		}
		if (!website) {
			return;
		}
		if (!date) {
			return;
		}
		const { data } = await axios.get<GroupForPage>('/api-v2/group/alias', {
			params: {
				website: website?.name,
				alias: group.alias,
				date,
				city,
			},
			...headers,
		});
		setStats({
			clicks: data.groupAnalytics[0]?.clicks,
			views: data.groupAnalytics[0]?.views,
			conversion: (data.groupAnalytics[0]?.clicks / data.groupAnalytics[0]?.views) * 100 ?? 0,
		});
		setData(data);
		setEditedReviews(data?.products.find((p) => p.product.id === editedReviews?.id)?.product);
		const offlineProducts = data.products.filter((p) =>
			p.product?.company?.locations.find((l) => l.city === city)
		);
		const onlineProducts = data.products.filter(
			(p) =>
				p.product?.company?.locations.find((l) => l.city == null) &&
				p.product.productCountry[0]?.price > 0
		);
		const freeProducts = data.products.filter(
			(p) =>
				p.product?.company?.locations.find((l) => l.city == null) &&
				(p.product?.productCountry[0]?.price == 0 || !p.product.productCountry[0]?.price)
		);
		setFreeProducts(freeProducts);
		const partnerProductOnline = onlineProducts.filter(
			(p) => new Date(p.partnerEndDate) >= new Date()
		);
		const onlineProductsSorted = onlineProducts
			.filter((p) => new Date(p.partnerEndDate) < new Date())
			.sort(sortProducts);
		onlineProductsSorted.unshift(...partnerProductOnline);
		setOnlineProducts(onlineProductsSorted);

		const partnerProductOffline = offlineProducts.filter(
			(p) => new Date(p.partnerEndDate) >= new Date()
		);
		const offlineProductsSorted = offlineProducts
			.filter((p) => new Date(p.partnerEndDate) < new Date())
			.sort(sortProducts);
		offlineProductsSorted.unshift(...partnerProductOffline);
		setOfflineProducts(offlineProductsSorted);
	};

	const loadAuthors = async (group: Group) => {
		const { data: authorProducts } = await axios.get<AuthorWithProducts[]>(
			'/api-v2/author/group',
			{
				params: {
					alias: group.alias,
				},
			}
		);
		setAuthorProducts(authorProducts);
	};

	const deleteProduct = async (product: ProductWithReviewsAndWebsite) => {
		await axios.delete<Product[]>('/api-v2/product/' + product.id, headers);
		loadData(group, website, date);
	};

	const selectCategory = (category: Category) => {
		setCategory(category);
		loadGroups(category);
	};

	const selectWebsite = (website: Website) => {
		setWebsite(website);
		loadData(group, website, date);
	};

	const selectGroup = (group: Group) => {
		setGroup(group);
		loadData(group, website, date);
	};

	const selectCity = (city: string) => {
		loadData(group, website, date, city);
	};

	const startEdit = (product: ProductWithReviewsAndWebsite) => {
		setEditedProduct(product);
		setIsEdited(true);
	};

	const startEditReviews = (product: ProductWithReviewsAndWebsite) => {
		setEditedReviews(product);
		setIsEditedReviews(true);
	};

	const closeEdit = () => {
		setEditedProduct(undefined);
		setIsEdited(false);
	};

	const startEditPartner = (product: ProductGroupInfo) => {
		if (!data) {
			return;
		}
		setEditedPartner({
			productId: product.product.id,
			groupId: data.id,
			partnerEndDate: product.partnerEndDate,
			placementEndDate: product.placementEndDate,
			isCpc: product.isCpc,
		});
	};

	const closeEditPartner = () => {
		setEditedPartner(undefined);
	};

	const saveEditPartner = () => {
		setEditedPartner(undefined);
		loadData(group, website, date);
	};

	const closeImport = () => {
		loadData(group, website, date);
		setIsImport(false);
	};

	const closeEditReviews = () => {
		setEditedReviews(undefined);
		setIsEditedReviews(false);
		loadData(group, website, date);
	};

	const saveEditReviews = async () => {
		loadData(group, website, date);
	};

	const saveEdit = () => {
		setEditedProduct(undefined);
		setIsEdited(false);
		loadData(group, website, date);
	};

	const columns = [
		{
			title: 'Компания',
			dataIndex: 'websiteProduct[0].initialRating',
			key: 'websiteProduct[0].initialRating',
			render: (text: number, record: ProductGroupInfo) => (
				<>
					<img style={{ width: 40, height: 40 }} src={record.product?.company?.image} />
				</>
			),
		},
		{
			title: 'Название',
			dataIndex: 'title',
			key: 'title',
			render: (text: number, record: ProductGroupInfo) => (
				<>
					<div>{record.product.title}</div>
					<div>
						{record.product.company.locations
							.filter((l) => l.city)
							.map((l) => (
								<Tag>{l.ruName}</Tag>
							))}
						{new Date(record.partnerEndDate) > new Date() && (
							<Tag color="green">
								Партнер до {moment(record.partnerEndDate).format('yyyy-MM-DD')}
							</Tag>
						)}
						{record.placementEndDate &&
							new Date(record.placementEndDate) > new Date() && (
								<Tag color="blue">
									Размещение до{' '}
									{moment(record.placementEndDate).format('yyyy-MM-DD')}
								</Tag>
							)}
						{record.placementEndDate &&
							new Date(record.placementEndDate) <= new Date() && (
								<Tag color="red">
									Был размещен до{' '}
									{moment(record.placementEndDate).format('yyyy-MM-DD')}
								</Tag>
							)}
						{record.isCpc &&
							record.product.company.balance !== undefined &&
							record.product.company.balance > 0 && (
								<Tag color="blue">Баланс: {record.product.company.balance}</Tag>
							)}
						{record.isCpc &&
							record.product.company.balance !== undefined &&
							record.product.company.balance <= 0 && (
								<Tag color="red">Баланса закончился</Tag>
							)}
					</div>
				</>
			),
		},
		{
			title: 'Рейтинг',
			dataIndex: 'websiteProduct[0].initialRating',
			key: 'websiteProduct[0].initialRating',
			render: (text: number, record: ProductGroupInfo) => (
				<>{record.product.websiteProduct[0]?.initialRating}</>
			),
		},
		{
			title: 'Дата обновления',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (text: string, record: ProductGroupInfo) =>
				format(new Date(record.product.updatedAt), 'yyyy-MM-dd'),
		},
		{
			title: 'Переходы',
			dataIndex: 'clicks',
			key: 'clicks',
			render: (text: number, record: ProductGroupInfo) => (
				<>{record.GroupOnProductAnalytics[0]?.clicks ?? 0}</>
			),
		},
		{
			title: 'CTR',
			dataIndex: 'ctr',
			key: 'ctr',
			render: (text: number, record: ProductGroupInfo) => {
				if (!record.GroupOnProductAnalytics[0]?.clicks) {
					return <>0%</>;
				} else {
					const conv =
						(record.GroupOnProductAnalytics[0]?.clicks /
							(data?.groupAnalytics[0].views ?? 0)) *
						100;
					return <>{conv.toFixed(0)}%</>;
				}
			},
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: ProductGroupInfo) => (
				<Space>
					<Button onClick={() => startEdit(record.product)} icon={<EditOutlined />} />
					<Button
						onClick={() => deleteProduct(record.product)}
						icon={<DeleteOutlined />}
					/>
					<Button onClick={() => startEditReviews(record.product)}>
						<MessageOutlined /> {record.product.review?.length ?? 0}
					</Button>
					<Button onClick={() => startEditPartner(record)} icon={<StarOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					{websites && website && (
						<Col>
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectWebsite(websites.find((w) => w.id === value) ?? website)
								}
								value={website.name}
								placeholder="Сайт"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{websites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						</Col>
					)}
					{categories && category && (
						<Col>
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectCategory(
										categories.find((w) => w.id === value) ?? category
									)
								}
								value={category.name}
								placeholder="Категория"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{Object.entries(groupByArray(categories, 'section')).map(
									(w: any) => (
										<OptGroup label={w[0]}>
											{w[1].map((y: any) => (
												<Option key={y.id} value={y.id}>
													{y.name}
												</Option>
											))}
										</OptGroup>
									)
								)}
							</Select>
						</Col>
					)}
					{groups && group && (
						<Col>
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectGroup(groups.find((w) => w.id === value) ?? group)
								}
								value={group.name}
								placeholder="Группа"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{groups.map((g) => (
									<Option key={g.id} value={g.id}>
										{g.name}
									</Option>
								))}
							</Select>
						</Col>
					)}
					<Col>
						<DatePicker
							onChange={(value) =>
								value && setDate(addDays(startOfMonth(value.toDate()), 1))
							}
							value={moment(date)}
							picker="month"
						/>
					</Col>
					<Col>
						<Select
							placeholder="Страна"
							onChange={(value: Country) => selectCountry(value)}
							value={country}
						>
							{countries.map((c) => (
								<Option key={c.value} value={c.value}>
									{c.name}
								</Option>
							))}
						</Select>
					</Col>
					{locations && (
						<Col>
							<Select
								placeholder="Город"
								onChange={(value: string) => selectCity(value)}
								showSearch
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{locations.map((c) => (
									<Option key={c.id} value={c.city}>
										{c.ruName}
									</Option>
								))}
							</Select>
						</Col>
					)}
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
					<Col>
						<Button
							shape="round"
							type="ghost"
							size="middle"
							onClick={() => setIsImport(true)}
						>
							Импортировать
						</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: 10, maxWidth: 600 }}>
					{stats && (
						<Col flex={1}>
							<Steps current={2}>
								<Step
									title={`Просмотры: ${stats?.views ?? 0}`}
									icon={<EyeOutlined />}
								/>
								<Step
									title={`Конверсия: ${stats?.conversion.toFixed(0)}%`}
									icon={<PercentageOutlined />}
								/>
								<Step
									title={`Переходы: ${stats?.clicks ?? 0}`}
									icon={<AimOutlined />}
								/>
							</Steps>
						</Col>
					)}
				</Row>
				{offlineProducts.length > 0 && (
					<Table columns={columns} dataSource={offlineProducts} pagination={false} />
				)}
				<Table columns={columns} dataSource={onlineProducts} pagination={false} />
				{freeProducts.length > 0 && (
					<Table columns={columns} dataSource={freeProducts} pagination={false} />
				)}
				{/* {authorProducts.length > 0 &&
					authorProducts.map((a) => (
						<>
							<h3>{a.name}</h3>
							<Table columns={columns} dataSource={a as any} pagination={false} />
						</>
					))} */}
			</Space>
			<Drawer
				title="Редактирование Продукта"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditProduct
						websites={websites}
						editedProduct={editedProduct}
						save={saveEdit}
					/>
				)}
			</Drawer>
			<Drawer
				title="Редактирование Отзывов"
				placement="right"
				width={'90%'}
				onClose={closeEditReviews}
				visible={isEditedReviews}
			>
				{editedReviews && website && (
					<EditReview
						save={saveEditReviews}
						reviews={editedReviews.review}
						company={editedReviews.company}
						product={editedReviews}
						website={website}
						close={closeEditReviews}
					/>
				)}
			</Drawer>
			<Drawer
				title="Редактирование партнёрства"
				placement="right"
				width={'30%'}
				onClose={closeEditPartner}
				visible={Boolean(editedPartner)}
			>
				{editedPartner && (
					<EditPartner
						save={saveEditPartner}
						productId={editedPartner.productId}
						groupId={editedPartner.groupId}
						placementEndDate={editedPartner.placementEndDate}
						partnerEndDate={editedPartner.partnerEndDate}
						isCpc={editedPartner.isCpc}
					/>
				)}
			</Drawer>
			<Drawer
				title="Импорт продуктов"
				placement="right"
				onClose={closeImport}
				visible={isImport}
			>
				{isImport && <ImportProducts save={closeImport} />}
			</Drawer>
		</>
	);
}
