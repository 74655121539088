import { Button, Col, DatePicker, Drawer, Input, Row, Select, Space, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined, MessageOutlined, BarChartOutlined } from '@ant-design/icons';
import { EditCompany } from './edit-company';
import { useHeaders } from '../../helpers/useHeaders';
import { Company, CompanyWithReviews, OrgType, orgTypes } from '../../interfaces/post.interface';
import { EditReview } from '../products/edit-review';
import { Category, Website } from '../../interfaces/page.interface';
import moment from 'moment';
import { startOfMonth, addDays } from 'date-fns';
import { CompanyStat } from './company-stat';
import { ImportCompany } from './import-company';

const { Option } = Select;

export function Companies() {
	const [data, setData] = useState<CompanyWithReviews[]>([]);
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [isStatOpened, setIsSatOpened] = useState<boolean>(false);
	const [editedCompany, setEditedCompany] = useState<CompanyWithReviews | undefined>();
	const [isEditedReviews, setIsEditedReviews] = useState<boolean>(false);
	const [isImport, setIsImport] = useState<boolean>(false);
	const [editedReviews, setEditedReviews] = useState<CompanyWithReviews>();
	const [websites, setWebsites] = useState<Website[]>();
	const [website, setWebsite] = useState<Website>();
	const [date, setDate] = useState<Date>(new Date());
	const headers = useHeaders();
	const [categories, setCategories] = useState<Category[]>();
	const [category, setCategory] = useState<Category>();
	const [search, setSearch] = useState<string>('');
	const [filteredData, setFilteredData] = useState<CompanyWithReviews[]>([]);

	useEffect(() => {
		loadWebsites();
		loadCategories();
	}, []);

	useEffect(() => {
		setFilteredData(data.filter((x) => x.name.toLowerCase().includes(search.toLowerCase())));
	}, [search]);

	useEffect(() => {
		if (date && website) {
			loadData(orgType, date, website);
		}
	}, [date, website]);

	const loadData = async (
		type: OrgType,
		date: Date,
		website?: Website,
		selectedCategory?: Category
	) => {
		if (selectedCategory) {
			setCategory(selectedCategory);
		}
		const { data } = await axios.get<CompanyWithReviews[]>('/api-v2/company/type/' + type, {
			...headers,
			params: {
				website: website?.name,
				date,
				categoryId: selectedCategory?.id,
			},
		});
		setData(data);
		setFilteredData(data);
		setEditedReviews(data?.find((p) => p.id === editedReviews?.id));
	};

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
		setWebsite(websitesData[0]);
	};

	const loadCategories = async () => {
		const { data: categories } = await axios.get<Category[]>('/api-v2/company-category', {
			...headers,
		});
		setCategories(categories);
	};

	const deleteCompany = async (company: CompanyWithReviews) => {
		await axios.delete<any[]>('/api-v2/company/' + company.id, headers);
		loadData(orgType, date, website, category);
	};

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadData(type, date, website, category);
	};

	const openStat = (company: CompanyWithReviews) => {
		setEditedCompany(company);
		setIsSatOpened(true);
	};

	const closeStat = () => {
		setEditedCompany(undefined);
		setIsSatOpened(false);
	};

	const startEdit = (company: CompanyWithReviews) => {
		setEditedCompany(company);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedCompany(undefined);
		setIsEdited(false);
	};

	const closeImport = () => {
		setIsImport(false);
		loadData(orgType, date, website, category);
	};

	const saveEdit = () => {
		setEditedCompany(undefined);
		setIsEdited(false);
		loadData(orgType, date, website, category);
	};

	const selectWebsite = (website: Website) => {
		setWebsite(website);
		loadData(orgType, date, website, category);
	};

	const selectCategory = (category?: Category) => {
		setWebsite(website);
		loadData(orgType, date, website, category);
	};

	const startEditReviews = (product: CompanyWithReviews) => {
		setEditedReviews(product);
		setIsEditedReviews(true);
	};

	const closeEditReviews = () => {
		setEditedReviews(undefined);
		setIsEditedReviews(false);
		loadData(orgType, date, website, category);
	};

	const saveEditReviews = async () => {
		loadData(orgType, date, website, category);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			render: (text: number, record: Company) => (
				<>
					{record.name} {record.isCPApartner && <Tag color="blue">CPA</Tag>}
					{record.admitadId && <Tag color="green">Admitad</Tag>}
					{record.advcakeId && <Tag color="green">Advcake</Tag>}
				</>
			),
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Баланс',
			dataIndex: 'balance',
			key: 'balance',
			filters: [{ text: 'С балансом', value: true }],
			onFilter: (value: string | number | boolean, record: CompanyWithReviews) => {
				if (value && typeof record.balance === 'number') {
					return true;
				}
				return false;
			},
			// sorter: (a: Company, b: Company) => (b.balance) ?? 0 - (a.balance ?? 1),
		},
		{
			title: 'Промокодов',
			dataIndex: 'codes',
			key: 'codes',
			render: (text: number, record: CompanyWithReviews) => <>{record._count.codes}</>,
			sorter: (a: CompanyWithReviews, b: CompanyWithReviews) =>
				a._count.codes - b._count.codes,
		},
		{
			title: 'Рейтинг',
			dataIndex: 'rating',
			key: 'rating',
			render: (text: number) => <>{text.toFixed(2)}</>,
		},
		{
			title: 'Просмотры',
			dataIndex: 'views',
			key: 'views',
			render: (text: number, record: CompanyWithReviews) => (
				<>{record.companyAnalytics[0]?.views ?? 0}</>
			),
			sorter: (a: CompanyWithReviews, b: CompanyWithReviews) =>
				(a.companyAnalytics[0]?.views ?? 0) - (b.companyAnalytics[0]?.views ?? 0),
		},
		{
			title: 'Переходы',
			dataIndex: 'clicks',
			key: 'clicks',
			render: (text: number, record: CompanyWithReviews) => (
				<>{record.companyAnalytics[0]?.clicks ?? 0}</>
			),
		},
		{
			title: 'CTR',
			dataIndex: 'ctr',
			key: 'ctr',
			render: (text: number, record: CompanyWithReviews) => {
				if (!record.companyAnalytics[0]?.clicks) {
					return <>0%</>;
				} else {
					const conv =
						(record.companyAnalytics[0]?.clicks /
							(record.companyAnalytics[0]?.views ?? 0)) *
						100;
					return <>{conv.toFixed(0)}%</>;
				}
			},
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => openStat(record)}>
						<BarChartOutlined />
					</Button>
					<Button onClick={() => startEditReviews(record)}>
						<MessageOutlined /> {record.review?.length ?? 0}
					</Button>
					<Button onClick={() => deleteCompany(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) => selectType(value)}
							value={orgType}
							placeholder="Тип компании"
						>
							{orgTypes.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<DatePicker
							onChange={(value) =>
								value && setDate(addDays(startOfMonth(value.toDate()), 1))
							}
							value={moment(date)}
							picker="month"
						/>
					</Col>
					<Col>
						{websites && website && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectWebsite(websites.find((w) => w.id === value) ?? website)
								}
								value={website.name}
								placeholder="Сайт"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{websites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						{categories && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectCategory(categories.find((w) => w.id === value))
								}
								placeholder="Категория"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{categories.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						<Input onChange={(e) => setSearch(e.target.value)} />
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
					<Col>
						<Button
							shape="round"
							type="ghost"
							size="middle"
							onClick={() => setIsImport(true)}
						>
							Импортировать
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={filteredData} />
			</Space>
			<Drawer
				title="Импорт компаний"
				placement="right"
				onClose={closeImport}
				visible={isImport}
			>
				{isImport && <ImportCompany save={closeImport} />}
			</Drawer>
			<Drawer
				title="Редактирование компании"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && <EditCompany editedCompany={editedCompany} save={saveEdit} />}
			</Drawer>
			<Drawer
				title="Редактирование Отзывов"
				placement="right"
				width={'90%'}
				onClose={closeEditReviews}
				visible={isEditedReviews}
			>
				{editedReviews && website && (
					<EditReview
						save={saveEditReviews}
						reviews={editedReviews.review}
						company={editedReviews}
						website={website}
						close={closeEditReviews}
					/>
				)}
			</Drawer>
			<Drawer
				title="Статистика"
				placement="right"
				width={'90%'}
				onClose={closeStat}
				visible={isStatOpened}
			>
				{editedCompany && <CompanyStat company={editedCompany} />}
			</Drawer>
		</>
	);
}
