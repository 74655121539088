import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditMono } from './edit-mono';
import { useHeaders } from '../../helpers/useHeaders';
import {
	SingleWebsite,
	SingleWebsiteType,
	singleWebsiteTypes,
} from '../../interfaces/single-website.interface';

const { Option } = Select;

export function MonoPage() {
	const [data, setData] = useState<SingleWebsite[]>([]);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [websiteType, setWebsiteType] = useState<SingleWebsiteType>(SingleWebsiteType.GROUP);
	const [editedWebsite, setEditedWebsite] = useState<SingleWebsite | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadData(websiteType);
	}, []);

	const loadData = async (type: SingleWebsiteType) => {
		const { data } = await axios.get<SingleWebsite[]>('/api-v2/website/single', {
			...headers,
			params: {
				type,
			},
		});
		setData(data);
	};

	const selectType = async (type: SingleWebsiteType) => {
		setWebsiteType(type);
		loadData(type);
	};

	const deleteWebsite = async (website: SingleWebsite) => {
		await axios.delete<any[]>('/api-v2/website/single/' + website.id, headers);
		loadData(websiteType);
	};

	const startEdit = (website: SingleWebsite) => {
		setEditedWebsite(website);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedWebsite(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedWebsite(undefined);
		setIsEdited(false);
		loadData(websiteType);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteWebsite(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						<Select onSelect={(value: any) => selectType(value)} value={websiteType} placeholder="Тип">
							{singleWebsiteTypes.map((g) => (
								<Option key={g.value} value={g.value}>
									{g.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование сайта"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && <EditMono editedWebsite={editedWebsite} save={saveEdit} />}
			</Drawer>
		</>
	);
}
