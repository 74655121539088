import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditCategory } from './edit-category';
import { Category, Website } from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';
import { PostCategory } from '../../interfaces/post.interface';

const { Option } = Select;

export function BlogCategories() {
	const [data, setData] = useState<PostCategory[]>([]);
	const [parentCategoryId, setParentCategoryId] = useState<number>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [websites, setWebsites] = useState<Website[]>();
	const [editedCategory, setEditedCategory] = useState<PostCategory | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadData();
		loadWebsites();
	}, []);

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
	};

	const loadData = async (parentCategoryId?: number) => {
		const { data } = await axios.get<any[]>('/api-v2/post-category/find', {
			...headers,
			params: {
				parentId: parentCategoryId,
			},
		});
		setData(data);
	};

	const deleteCategory = async (category: Category) => {
		await axios.delete<any[]>('/api-v2/post-category/' + category.id, headers);
		loadData(parentCategoryId);
	};

	const selectCategory = (categorId: number) => {
		setParentCategoryId(categorId);
		loadData(categorId);
	};

	const startEdit = (category: PostCategory) => {
		setEditedCategory(category);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedCategory(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedCategory(undefined);
		setIsEdited(false);
		loadData(parentCategoryId);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Верхняя категория',
			dataIndex: 'parentId',
			key: 'parentId',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteCategory(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						<Select
							style={{ width: 200 }}
							showSearch
							onSelect={(value: number) => selectCategory(value)}
							value={parentCategoryId}
							placeholder="Категория"
							optionFilterProp="children"
							filterOption={(inputValue: string, option?: any) =>
								option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
								0
							}
						>
							{data.map((s) => (
								<option key={s.id} value={s.id}>
									{s.name}
								</option>
							))}
							<option value={0}>Все</option>
						</Select>
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>

				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование категории"
				placement="right"
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && websites && (
					<EditCategory
						editedCategory={editedCategory}
						save={saveEdit}
						websites={websites}
					/>
				)}
			</Drawer>
		</>
	);
}
