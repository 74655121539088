import { Button, Col, Drawer, Row, Select, Space, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditCode } from './edit-code';
import { Website } from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';
import { Code, codeTypes } from '../../interfaces/codes.interface';
import { Company, CompanyWithReviews, OrgType, orgTypes } from '../../interfaces/post.interface';
import { format } from 'date-fns';

const { Option } = Select;

export function CodePage() {
	const [data, setData] = useState<Code[]>([]);
	const [companies, setCompanies] = useState<Company[]>([]);
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [websites, setWebsites] = useState<Website[]>();
	const [website, setWebsite] = useState<Website>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [filteredCompany, setFilteredCompany] = useState<string>();
	const [editedCode, setEditedCode] = useState<Code | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadWebsites();
	}, []);

	useEffect(() => {
		if (website) {
			loadCompanies(orgType, website);
			loadData(website);
		}
	}, [website]);

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
		setWebsite(websitesData[0]);
	};

	const loadCompanies = async (type: OrgType, website?: Website) => {
		const { data } = await axios.get<CompanyWithReviews[]>('/api-v2/company/type/' + type, {
			...headers,
			params: {
				website: website?.name,
			},
		});
		setCompanies(data);
	};

	const loadData = async (selectedWebsite: Website | undefined, companyAlias?: string) => {
		if (!selectedWebsite) {
			return;
		}
		const { data } = await axios.get<any[]>('/api-v2/code/find', {
			params: {
				website: selectedWebsite.name,
				companyAlias,
				hidden: true,
			},
			...headers,
		});
		setData(data);
	};

	const deleteCode = async (code: Code) => {
		await axios.delete<any[]>('/api-v2/code/' + code.id, headers);
		loadData(website);
	};

	const selectCompany = (companyAlias: string) => {
		setFilteredCompany(companyAlias)
		loadData(website, companyAlias);
	};

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadCompanies(type);
	};

	const selectWebsite = (website: Website) => {
		setWebsite(website);
		loadData(website);
	};

	const startEdit = (code: Code) => {
		setEditedCode(code);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedCode(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedCode(undefined);
		setIsEdited(false);
		loadData(website, filteredCompany);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Компания',
			dataIndex: 'company',
			key: 'company',
			render: (text: number, record: Code) => (
				<>
					<img style={{ width: 40, height: 40 }} src={record?.company?.image} />{' '}
					{record.company.name}
				</>
			),
		},
		{
			title: 'Название',
			dataIndex: 'title',
			key: 'title',
			render: (text: number, record: Code) => (
				<>
					<div>{record.title}</div>
					<div>
						{record.admitadId && <Tag color="blue">Admitad</Tag>}
						{record.advcakeId && <Tag color="blue">Advcake</Tag>}
					</div>
				</>
			),
		},
		{
			title: 'Скидка',
			dataIndex: 'discount',
			key: 'discount',
			render: (text: number, record: Code) => (
				<>
					{record.value} {codeTypes.find((c) => c.value === record.codeType)?.name}
				</>
			),
		},
		{
			title: 'Клики',
			dataIndex: 'clicks',
			key: 'clicks',
			sorter: (a: Code, b: Code) => a.clicks - b.clicks,
		},
		{
			title: 'Likes',
			dataIndex: 'likes',
			key: 'likes',
			sorter: (a: Code, b: Code) => a.likes - b.likes,
		},
		{
			title: 'Dislikes',
			dataIndex: 'dislikes',
			key: 'dislikes',
			sorter: (a: Code, b: Code) => a.dislikes - b.dislikes,
		},
		{
			title: 'Истекает',
			dataIndex: 'dateEnd',
			key: 'dateEnd',
			sorter: (a: Code, b: Code) => (new Date(a.dateEnd) > new Date(b.dateEnd) ? 1 : -1),
			render: (text: number, record: Code) => (
				<>
					<div>{format(new Date(record.dateEnd), 'dd-MM-yyyy')}</div>
					<div>
						{new Date(record.dateEnd) < new Date() && <Tag color="red">Срок истёк</Tag>}
					</div>
				</>
			),
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteCode(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						{websites && website && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectWebsite(websites.find((w) => w.id === value) ?? website)
								}
								value={website.name}
								placeholder="Сайт"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{websites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) => selectType(value)}
							value={orgType}
							placeholder="Тип компании"
						>
							{orgTypes.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) => selectCompany(value)}
							showSearch
							optionFilterProp="children"
							filterOption={(inputValue: string, option?: any) =>
								option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
								0
							}
							placeholder="Компания"
						>
							{companies.map((c) => (
								<Option key={c.id} value={c.alias}>
									{c.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование поста"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditCode editedCode={editedCode} save={saveEdit} websites={websites ?? []} />
				)}
			</Drawer>
		</>
	);
}
