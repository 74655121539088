import React, { useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import './login.css';
import { authState } from '../../states/app.state';

export function Login() {
	const setAuth = useSetRecoilState(authState);
	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem('token')) {
			navigate('/');
		}
	}, []);

	const onSubmit = async (data: any) => {
		try {
			const res = await axios.post<{ access_token: string }>('/api-v2/auth/login', data);
			if (res.data.access_token) {
				localStorage.setItem('token', res.data.access_token);
				setAuth({ isAuthed: true, token: res.data.access_token });
				navigate('/');
			} else {
				notification.error({
					message: 'Неверный логин или пароль',
				});
			}
		} catch (e) {
			notification.error({
				message: 'Неверный логин или пароль',
			});
		}
	};

	return (
		<div className="login__wrapper">
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={{ remember: true }}
				onFinish={onSubmit}
				autoComplete="off"
			>
				<Form.Item
					label="Email"
					name="email"
					rules={[{ required: true, message: 'Ведите логин' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Пароль"
					name="password"
					rules={[{ required: true, message: 'Введить пароль!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit">
						Отправить
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
