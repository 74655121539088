import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditEvent } from './edit-event';
import { Website } from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';
import { Event, EventPage } from '../../interfaces/event.interface';
import { format } from 'date-fns';

const { Option } = Select;

export function EventsPage() {
	const [data, setData] = useState<Event[]>([]);
	const [websites, setWebsites] = useState<Website[]>();
	const [website, setWebsite] = useState<Website>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [editedEvent, setEditedEvent] = useState<Event | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadWebsites();
	}, []);

	useEffect(() => {
		if (website) {
			loadData(website);
		}
	}, [website]);

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
		setWebsite(websitesData[0]);
	};

	const loadData = async (selectedWebsite: Website | undefined) => {
		if (!selectedWebsite) {
			return;
		}
		const { data } = await axios.get<EventPage>('/api-v2/event/find', {
			params: {
				limit: 1000,
				offset: 0,
				website: selectedWebsite.name,
			},
			...headers,
		});
		setData(data.events);
	};

	const deleteEvent = async (event: Event) => {
		await axios.delete<any[]>('/api-v2/event/' + event.id, headers);
		loadData(website);
	};

	const selectWebsite = (website: Website) => {
		setWebsite(website);
		loadData(website);
	};

	const startEdit = (event: Event) => {
		setEditedEvent(event);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedEvent(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedEvent(undefined);
		setIsEdited(false);
		loadData(website);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			render: (text: string) => format(new Date(text), 'yyyy-MM-dd'),
		},
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (_: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteEvent(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						{websites && website && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectWebsite(websites.find((w) => w.id === value) ?? website)
								}
								value={website.name}
								placeholder="Сайт"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{websites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование поста"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditEvent
						editedEvent={editedEvent}
						save={saveEdit}
						websites={websites ?? []}
					/>
				)}
			</Drawer>
		</>
	);
}
