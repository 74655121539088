import { Alert, Button, Col, Form, Input, Row, Select, Table } from 'antd';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useHeaders } from '../../helpers/useHeaders';
import { Company, OrgType, orgTypes, UserWithCompany } from '../../interfaces/post.interface';
import { Category } from '../../interfaces/page.interface';

const { Option } = Select;

export function PartnersPage() {
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [companies, setCompanies] = useState<Company[]>();
	const [users, setUsers] = useState<UserWithCompany[]>([]);
	const [result, setResult] = useState<string | undefined>();
	const [form] = Form.useForm();
	const headers = useHeaders();

	useEffect(() => {
		loadPartners();
		loadComapanies(orgType);
	}, []);

	const loadPartners = async () => {
		const { data } = await axios.get<UserWithCompany[]>('/api-v2/user/partners', {
			...headers,
		});
		setUsers(data);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Имя',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Компания',
			dataIndex: 'company',
			key: 'company',
			render: (_: number, record: UserWithCompany) => (
				<>{record.Company.length > 0 ? record.Company[0].name : '-'}</>
			),
		},
		{
			title: 'Баланс',
			dataIndex: 'balance',
			key: 'balance',
			render: (_: number, record: UserWithCompany) => (
				<>{record.Company.length > 0 ? record.Company[0].balance : '-'}</>
			),
		},
	];

	const saveData = async (res: any) => {
		try {
			await axios.post(
				'/api-v2/auth/register',
				{
					...res,
				},
				headers
			);
			form.resetFields();
			setResult('Сохранено!');
		} catch (e) {
			if (e instanceof AxiosError) {
				setResult(e.message);
			}
		}
	};

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadComapanies(type);
	};

	const loadComapanies = async (type: OrgType) => {
		const { data } = await axios.get<Company[]>('/api-v2/company/type/' + type, headers);
		setCompanies(data);
	};

	return (
		<>
			<Table columns={columns} dataSource={users} />
			<h1>Добавление нового партнёра</h1>
			<Form onFinish={saveData} form={form} layout="vertical">
				{result && <Alert message={result} type="info" />}
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="email" label="Email" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="password" label="Пароль" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) => selectType(value)}
							value={orgType}
							placeholder="Тип компании"
						>
							{orgTypes.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
						{companies && (
							<Form.Item
								name="companyId"
								label="Компания"
								rules={[{ required: true }]}
							>
								<Select
									placeholder="Компания"
									showSearch
									optionFilterProp="children"
									filterOption={(inputValue: string, option?: any) =>
										option?.children
											?.toLowerCase()
											.indexOf(inputValue.toLowerCase()) >= 0
									}
								>
									{companies.map((company) => (
										<Option key={company.id} value={company.id}>
											{company.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
					</Col>
				</Row>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Создать
					</Button>
				</Form.Item>
			</Form>
		</>
	);
}

export interface EditCategoryProps {
	editedCategory?: Category;
	save(): void;
}
