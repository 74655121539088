import { Button, Col, Drawer, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EditPost } from './edit-post';
import { Category, Website } from '../../interfaces/page.interface';
import { useHeaders } from '../../helpers/useHeaders';
import { Post } from '../../interfaces/post.interface';
import { format } from 'date-fns';
import { SingleWebsite, SingleWebsiteType } from '../../interfaces/single-website.interface';

const { Option } = Select;

export function Blog() {
	const [data, setData] = useState<Post[]>([]);
	const [websites, setWebsites] = useState<Website[]>();
	const [singleWebsites, setSingleWebsites] = useState<SingleWebsite[]>([]);
	const [website, setWebsite] = useState<Website>();
	const [singleWebsite, setSingleWebsite] = useState<SingleWebsite>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [editedPost, setEditedPost] = useState<Post | undefined>();
	const headers = useHeaders();

	useEffect(() => {
		loadWebsites();
		loadSingleWebsites();
	}, []);

	useEffect(() => {
		if (website) {
			loadData(website);
		}
	}, [website]);

	const loadWebsites = async () => {
		const { data: websitesData } = await axios.get<Website[]>('/api-v2/website', {
			...headers,
		});
		setWebsites(websitesData);
		setWebsite(websitesData[0]);
	};

	const loadSingleWebsites = async () => {
		const { data: data1 } = await axios.get<SingleWebsite[]>('/api-v2/website/single', headers);
		const { data: data2 } = await axios.get<SingleWebsite[]>('/api-v2/website/single', {
			...headers,
			params: {
				type: SingleWebsiteType.BLOG,
			},
		});
		setSingleWebsites(data1.concat(data2));
	};

	const loadData = async (selectedWebsite: Website | undefined) => {
		if (!selectedWebsite) {
			return;
		}
		const { data } = await axios.get<any[]>('/api-v2/blog/find', {
			params: {
				limit: 1000,
				offset: 0,
				website: selectedWebsite.name,
			},
			...headers,
		});
		setData(data);
	};

	const loadDataSingle = async (selectedWebsite: SingleWebsite | undefined) => {
		if (!selectedWebsite) {
			return;
		}
		const { data } = await axios.get<any[]>('/api-v2/blog/findSingle', {
			params: {
				limit: 1000,
				offset: 0,
				singleWebsite: selectedWebsite.name,
			},
			...headers,
		});
		setData(data);
	};

	const deletePost = async (category: Category) => {
		await axios.delete<any[]>('/api-v2/blog/' + category.id, headers);
		loadData(website);
	};

	const selectWebsite = (website?: Website) => {
		setWebsite(website);
		setSingleWebsite(undefined);
		loadData(website);
	};

	const selectSingleWebsite = (website?: SingleWebsite) => {
		setSingleWebsite(website);
		setWebsite(undefined);
		loadDataSingle(website);
	};

	const startEdit = (post: Post) => {
		setEditedPost(post);
		setIsEdited(true);
	};

	const closeEdit = () => {
		setEditedPost(undefined);
		setIsEdited(false);
	};

	const saveEdit = () => {
		setEditedPost(undefined);
		setIsEdited(false);
		loadData(website);
	};

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Дата',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: string) => format(new Date(text), 'yyyy-MM-dd'),
		},
		{
			title: 'Название',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Alias',
			dataIndex: 'alias',
			key: 'alias',
		},
		{
			title: 'Просмотры',
			dataIndex: 'views',
			key: 'views',
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: any) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deletePost(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row gutter={10}>
					<Col>
						{websites && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectWebsite(websites.find((w) => w.id === value) ?? website)
								}
								value={website?.name}
								placeholder="Сайт"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{websites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						{singleWebsites && (
							<Select
								style={{ width: 200 }}
								showSearch
								onSelect={(value: any) =>
									selectSingleWebsite(singleWebsites.find((w) => w.id === value))
								}
								value={singleWebsite?.name}
								placeholder="Сайт моно"
								optionFilterProp="children"
								filterOption={(inputValue: string, option?: any) =>
									option?.children
										?.toLowerCase()
										.indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{singleWebsites.map((w) => (
									<Option key={w.id} value={w.id}>
										{w.name}
									</Option>
								))}
							</Select>
						)}
					</Col>
					<Col>
						<Button
							shape="round"
							type="primary"
							size="middle"
							icon={<PlusOutlined />}
							onClick={() => setIsEdited(true)}
						>
							Добавить
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={data} />
			</Space>
			<Drawer
				title="Редактирование поста"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditPost
						singleWebsites={singleWebsites}
						editedPost={editedPost}
						save={saveEdit}
						websites={websites ?? []}
					/>
				)}
			</Drawer>
		</>
	);
}
