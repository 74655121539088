import {
	Button,
	Checkbox,
	Col,
	Divider,
	Drawer,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Space,
	Table,
} from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Quill from '../../components/quill/quill';
import Uploader from '../../components/uploader/uploader';
import { useHeaders } from '../../helpers/useHeaders';
import {
	Author,
	AuthorWithProducts,
	Company,
	OrgType,
	orgTypes,
} from '../../interfaces/post.interface';
import { Product, ProductWithReviewsAndWebsite } from '../../interfaces/product.interface';
import { EditProduct } from '../products/edit-product';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

const { Option } = Select;

export function EditAuthor({ editedAuthor, save }: EditPostProps) {
	const [data, setData] = useState<AuthorWithProducts>();
	const headers = useHeaders();
	const [orgType, setOrgType] = useState<OrgType>(OrgType.SCHOOL);
	const [companies, setCompanies] = useState<Company[]>();
	const [editedProduct, setEditedProduct] = useState<ProductWithReviewsAndWebsite | undefined>();
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [form] = Form.useForm();

	useEffect(() => {
		loadData();
		loadComapanies(orgType);
	}, []);

	const loadData = async () => {
		if (!editedAuthor?.id) {
			return;
		}
		const { data } = await axios.get<AuthorWithProducts>('/api-v2/author/' + editedAuthor.id, {
			...headers,
		});
		setData(data);
		form.setFieldsValue(data);
	};

	const saveEdit = () => {
		setEditedProduct(undefined);
		setIsEdited(false);
		loadData();
	};

	const selectType = (type: OrgType) => {
		setOrgType(type);
		loadComapanies(type);
	};

	const closeEdit = () => {
		setEditedProduct(undefined);
		setIsEdited(false);
	};

	const loadComapanies = async (type: OrgType) => {
		const { data } = await axios.get<Company[]>('/api-v2/company/type/' + type, headers);
		setCompanies(data);
	};

	const deleteProduct = async (product: ProductWithReviewsAndWebsite) => {
		await axios.delete<Product>('/api-v2/product/' + product.id, headers);
		loadData();
	};

	const startEdit = (product: ProductWithReviewsAndWebsite) => {
		setEditedProduct(product);
		setIsEdited(true);
	};

	const saveData = async (res: Author) => {
		if (!editedAuthor?.id) {
			await axios.post<Author>(
				'/api-v2/author',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Author>(
				'/api-v2/author/' + editedAuthor.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	const columns = [
		{
			title: 'Название',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Дата обновления',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (text: string) => format(new Date(text), 'yyyy-MM-dd'),
		},
		{
			title: 'Действие',
			key: 'operation',
			dataIndex: '',
			render: (text: string, record: ProductWithReviewsAndWebsite) => (
				<Space>
					<Button onClick={() => startEdit(record)}>Изменить</Button>
					<Button onClick={() => deleteProduct(record)} icon={<DeleteOutlined />} />
				</Space>
			),
		},
	];

	return (
		<>
			<Form onFinish={saveData} form={form} layout="vertical">
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="name" label="Название" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="link" label="Партнёрская ссылка">
							<Input />
						</Form.Item>
						<Form.Item name="rating" label="Рейтинг">
							<InputNumber min={0} max={5} step={0.1} />
						</Form.Item>
						<Form.Item name="image" noStyle getValueFromEvent={(e) => e}>
							<Uploader entity="companies" text="Изображение" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Select
							style={{ width: 200 }}
							onSelect={(value: any) => selectType(value)}
							value={orgType}
							placeholder="Тип компании"
						>
							{orgTypes.map((w) => (
								<Option key={w.value} value={w.value}>
									{w.name}
								</Option>
							))}
						</Select>
						{companies && (
							<Form.Item name="companyId" label="Компания">
								<Select placeholder="Компания">
									{companies.map((company) => (
										<Option key={company.id} value={company.id}>
											{company.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
						<Form.Item name="description" label="Описание" rules={[{ required: true }]}>
							<Quill />
						</Form.Item>
						<Form.Item name="isCPApartner" valuePropName="checked">
							<Checkbox>CPA партнёр</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Сохранить
					</Button>
				</Form.Item>
			</Form>
			<Divider />
			<h2>Курсы</h2>
			<Space direction="horizontal">
				<Button
					shape="round"
					type="primary"
					size="large"
					icon={<PlusOutlined />}
					onClick={() => setIsEdited(true)}
				>
					Добавить
				</Button>
			</Space>
			{data?.product && (
				<Table columns={columns} dataSource={data.product} pagination={false} />
			)}
			<Drawer
				title="Редактирование Продукта"
				placement="right"
				width={'90%'}
				onClose={closeEdit}
				visible={isEdited}
			>
				{isEdited && (
					<EditProduct
						editedProduct={editedProduct}
						save={saveEdit}
						authorId={editedAuthor?.id}
					/>
				)}
			</Drawer>
		</>
	);
}

export interface EditPostProps {
	editedAuthor?: Author;
	save(): void;
}
