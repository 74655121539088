import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHeaders } from '../../helpers/useHeaders';
import { Category, Website } from '../../interfaces/page.interface';
import { PostCategory } from '../../interfaces/post.interface';

const { Option } = Select;

export function EditCategory({ editedCategory, save, websites }: EditCategoryProps) {
	const [categories, setCategories] = useState<PostCategory[]>([]);
	const headers = useHeaders();

	useEffect(() => {
		loadCategories();
	}, []);

	const loadCategories = async () => {
		const { data } = await axios.get<any[]>('/api-v2/post-category/find', {
			...headers,
		});
		setCategories(data);
	};

	const saveData = async (res: PostCategory) => {
		if (!editedCategory?.id) {
			await axios.post<Category>(
				'/api-v2/post-category',
				{
					...res,
				},
				headers
			);
		} else {
			await axios.patch<Category>(
				'/api-v2/post-category/' + editedCategory.id,
				{
					...res,
				},
				headers
			);
		}
		save();
	};

	return (
		<Form onFinish={saveData} initialValues={editedCategory} layout="vertical">
			<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
				<Input />
			</Form.Item>
			<Form.Item name="parentId" label="Родительская категория">
				<Select
					style={{ width: 200 }}
					showSearch
					placeholder="Категория"
					optionFilterProp="children"
					filterOption={(inputValue: string, option?: any) =>
						option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
					}
				>
					{categories.map((s) => (
						<Option key={s.id} value={s.id}>
							{s.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item name="metaTitle" label="MetaTitle">
				<Input />
			</Form.Item>
			<Form.Item name="metaDescription" label="MetaDescription">
				<TextArea rows={4} />
			</Form.Item>
			<Form.Item name="websiteId" label="Сайт">
				<Select placeholder="Сайт">
					{websites.map((w) => (
						<Option key={w.id} value={w.id}>
							{w.name}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</Form.Item>
		</Form>
	);
}

export interface EditCategoryProps {
	editedCategory?: PostCategory;
	websites: Website[];
	save(): void;
}
